/* globals Turbolinks */

// https://medium.com/@kosovacsedad/how-to-turbolinks-5-scroll-position-persistence-6e4435a60b2e

Turbolinks.scroll = {};

document.addEventListener('turbolinks:load', () => {
  document.querySelectorAll('[data-turbolinks-scroll="false"]').forEach((element) => {
    element.addEventListener('click', () => {
      Turbolinks.scroll.top = document.scrollingElement.scrollTop;
    });
    element.addEventListener('submit', () => {
      Turbolinks.scroll.top = document.scrollingElement.scrollTop;
    });
  });

  if (Turbolinks.scroll.top) {
    document.scrollingElement.scrollTo(0, Turbolinks.scroll.top);
  }

  Turbolinks.scroll = {};
});
