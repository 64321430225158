import { Controller } from "stimulus";
import { ajax } from "@rails/ujs";

export default class extends Controller {
  static targets = ["teamId"];
  static values = {
    url: String
  };

  update() {
    ajax({
      url: `${this.urlValue}&team_id=${this.teamIdTarget.value}`,
      type: "get",
      dataType: "script",
    });
  }
}
