import { Controller } from 'stimulus';
import { enableFields, disableFields } from '../lib/utils';

export default class extends Controller {
  static targets = ['accountType', 'individualField', 'businessField'];

  static values = {
    autofocus: Boolean
  };

  initialize() {
    this.update();
    if (this.autofocusValue) { this.focusFirstInput(); }
  }

  update() {
    if (this.accountTypeTarget.value === 'individual') {
      enableFields(this.individualFieldTargets);
      disableFields(this.businessFieldTargets);
    } else {
      enableFields(this.businessFieldTargets);
      disableFields(this.individualFieldTargets);
    }
  }

  focusFirstInput() {
    if (this.accountTypeTarget.value === 'individual') {
      this.element.querySelector('#user_first_name').focus();
    } else {
      this.element.querySelector('#account_name').focus();
    }
  }
}
