import { Controller } from 'stimulus';

export default class extends Controller {
  onChange = () => {
    if (this.defaultOption.selected) {
      Array.from(this.element.options).forEach((option) => {
        if (option === this.defaultOption) { return; }
        option.selected = false;
      });
    }
  };

  connect() {
    this.selectDefault();
    this.element.addEventListener('change', this.onChange);
  }

  disconnect() {
    this.element.removeEventListener('change', this.onChange);
  }

  get hasSelection() {
    return this.element.selectedIndex !== -1;
  }

  get defaultOption() {
    if (!this._defaultOption) {
      this._defaultOption = this.element.querySelector('[value=""]');
    }
    return this._defaultOption;
  }

  selectDefault() {
    if (this.hasSelection) { return; }
    this.defaultOption.selected = true;
  }
}
