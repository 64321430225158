import { Controller } from 'stimulus';

export default class extends Controller {
  initialize() {
    this.element.addEventListener('click', this.toggleElement);
  }

  toggleElement(e) {
    if (e.target.tagName === 'LI') {
      e.target.classList.toggle('expanded');
    }
  }
}
