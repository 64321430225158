import { Controller } from 'stimulus';
import List from 'list.js';

export default class extends Controller {
  static values = {
    input: String,
    list: String
  };

  connect() {
    this.list = new List(this.element, {
      searchClass: this.inputValue,
      listClass: this.listValue,
      valueNames: [{ data: ['name'] }]
    });
  }
}
