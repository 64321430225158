import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['resource', 'bulkEditBtn'];

  connect() {
    const selectAll = this.selectText()
    if (selectAll) {
      selectAll.addEventListener('click', () => {
        this.toggleSelectAll()
      })
    }
  }

  toggleSelectAll() {
    const areAllChecked = this.areAllResourcesChecked();
    const newState = !areAllChecked;
    this.selectText().innerHTML = newState ? 'Deselect All' : 'Select All';

    this.resourceTargets.forEach(checkbox => {
      if (!this.isMobileTable(checkbox)) {
        checkbox.checked = newState;
      }
    });

    this.updateBulkEditButtonVisibility(newState);
    this.updateSelectedResources();
  }

  areAllResourcesChecked() {
    const uniqueResources = new Set(this.getResourceIds());
    return uniqueResources.size > 0 && [...uniqueResources].every(id => {
      const checkbox = document.getElementById(`resource_check_${id}`);
      return checkbox && checkbox.checked;
    });
  }
  getResourceIds() {
    return this.resourceTargets.map(resource => resource.id.split('_').pop());
  }

  isMobileTable(checkbox) {
    const closestTable = checkbox.closest('table');
    return closestTable && closestTable.classList.contains('d-mobile');
  }

  updateBulkEditButtonVisibility(isVisible) {
    this.bulkEditBtnTarget.classList.toggle('d-none', !isVisible);
  }

  updateSelectedResources() {
    const uniqueSelectedResources = new Set(this.getSelectedResourceIds());
    document.getElementById('resource_ids').value = [...uniqueSelectedResources].join(',');
  }

  getSelectedResourceIds() {
    return this.resourceTargets.filter(resource => resource.checked).map(resource => resource.id.split('_').pop());
  }

  toggleSelect() {
    const isChecked = this.resourceTargets.some(checkbox => checkbox.checked);
    this.selectText().innerHTML = this.areAllResourcesChecked() ? 'Deselect All' : 'Select All';
    this.updateBulkEditButtonVisibility(isChecked);
    this.updateSelectedResources();
  }

  selectText(){
    return document.getElementsByClassName('resource-center__toggle-select')[0]
  }
}
