import { Controller } from 'stimulus';
import Sortable from 'sortablejs';
import { random } from 'lib/utils';

export default class extends Controller {
  static targets = ['column'];

  connect() {
    this.groupId = `group-${random()}`;

    this.columnTargets.forEach((column) => {
      this.sortable = Sortable.create(column, {
        group: this.groupId,
        sort: false,
        filter: '.sequence-card__no-drag',
        preventOnFilter: true,
        onAdd: this.fireAddEvent.bind(this)
      });
    });
  }

  fireAddEvent(event) {
    const e = new CustomEvent('sequence-dragdrop.add', { detail: event });
    event.item.dispatchEvent(e);
  }
}
