import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['team', 'role'];

  updateForm = () => {
    const { value } = this.teamTarget;
    const disabled = value === '';
    this.roleTarget.disabled = disabled;
  };

  initialize() {
    this.updateForm();
  }

  connect() {
    this.teamTarget.addEventListener('change', this.updateForm);
  }

  disconnect() {
    this.teamTarget.removeEventListener('change', this.updateForm);
  }
}
