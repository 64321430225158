import { Controller } from 'stimulus';
import { ajax } from '@rails/ujs';
import { isDateSelector } from '../lib/utils';

export default class extends Controller {
  static targets = ['menu', 'title', 'subtitle', 'content'];

  handleDocumentClick = (e) => {
    if (!this.isOpen) { return; }
    if (this.menuTarget.contains(e.target)) { return; }
    if (isDateSelector(e.target)) { return; }
    this.close();
  };

  connect() {
    document.addEventListener('click', this.handleDocumentClick);
  }

  disconnect() {
    document.removeEventListener('click', this.handleDocumentClick);
  }

  get isOpen() {
    return this.menuTarget.classList.contains('slide-menu--open');
  }

  get isClosed() {
    return !this.isOpen;
  }

  get isDynamic() {
    return (this.menuTarget.dataset.dynamic === 'true');
  }

  toggle(e) {
    this.cancelEvent(e);
    this.menuTarget.classList.toggle('slide-menu--open');
    this.updateUrl();
  }

  open(e) {
    if (e.target.closest('form.no-slide-menu')) { return; }
    this.updateTitle(e.currentTarget.dataset.slideMenuTitle);
    this.updateSubtitle(e.currentTarget.dataset.slideMenuSubtitle);
    this.updateContent(e.currentTarget.dataset.slideMenuUrl);
    this.isOpen ? this.cancelEvent(e) : this.toggle(e);
  }

  close(e) {
    if (this.isDynamic) { this.resetContent(); }
    this.isOpen ? this.toggle(e) : this.cancelEvent(e);
  }

  cancelEvent(e) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
  }

  updateUrl() {
    const url = new URL(window.location);

    if (this.isOpen && !this.isDynamic) {
      url.searchParams.set('slide_menu', '1');
    } else if (this.isClosed) {
      url.searchParams.delete('slide_menu');
    }
    window.history.replaceState({}, '', url);
  }

  updateTitle(title) {
    if (!title) { return; }

    this.titleTarget.innerText = title;
  }

  updateSubtitle(subtitle) {
    if (!subtitle) { return; }

    this.subtitleTarget.innerText = subtitle;
  }

  updateContent(url) {
    if (!url) { return; }

    this.resetContent();

    ajax({
      type: 'GET',
      url,
      dataType: 'script'
    });
  }

  resetContent() {
    this.contentTarget.innerHTML = '<div class="loader"><div class="spinner"></div></div>';
  }
}
