import { Controller } from "stimulus";
import { ajax } from "@rails/ujs";

export default class extends Controller {
  static targets = ["selectUser"];
  static values = {
    url: String,
    redirectUrl: String
  };

  update() {
    const licenseId = this.selectUserTarget.value;
    const url = `${this.urlValue}?license_id=${licenseId}`;

    const data = new FormData();
    if (this.redirectUrlValue) {
      data.append('additional_redirect_path', this.redirectUrlValue);
    }

    ajax({
      url: url,
      type: "POST",
      dataType: "script",
      data
    });
  }
}
