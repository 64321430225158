import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['toggleTrigger', 'toggleTriggerContainer'];

  connect() {
    this.hideToggleTriggerContainer();
  }

  hideToggleTriggerContainer() {
    if(this.toggleTriggerTarget.checked) {
      this.toggleTriggerContainerTarget.classList.remove('d-none');
    } else {
      this.toggleTriggerContainerTarget.classList.add('d-none');

      const checkboxes = this.toggleTriggerContainerTarget.querySelectorAll('input[type="checkbox"]');
      checkboxes.forEach((checkbox) => {
        checkbox.checked = false;
      });
    }
  }
}