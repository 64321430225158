import { Controller } from 'stimulus';

export default class extends Controller {
  get scrollTopMax() {
    return this.element.scrollHeight - this.element.offsetHeight;
  }

  initialize() {
    this.check();
  }

  check() {
    if (this.element.scrollHeight > 0 && (this.element.scrollTop >= this.scrollTopMax)) {
      this.element.classList.add('more-content--none');
    } else {
      this.element.classList.remove('more-content--none');
    }
  }
}
