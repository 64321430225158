import { Controller } from 'stimulus';
import { cancelEvent } from '../lib/utils';

export default class extends Controller {
  static targets = ['template'];

  removeField(event) {
    cancelEvent(event);
    event.currentTarget.closest('.form__field-option').remove();
  }

  addField(event) {
    cancelEvent(event);
    const element = this.templateTarget.cloneNode(true);
    element.querySelectorAll('.form__input').forEach(element => {
      element.value = '';
    });
    this.element.insertBefore(element, event.currentTarget);
  }
}
