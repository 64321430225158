import { Controller } from 'stimulus';
import Choices from 'choices.js';

export default class extends Controller {
  initialize() {
    this.choices = new Choices(this.element, {
      searchPlaceholderValue: 'Search...',
      itemSelectText: '',
      position: 'below'
    });
  }
}
