import { Controller } from 'stimulus';
import { ajax } from '@rails/ujs';

export default class extends Controller {
  static targets = [
    'replyButton',
    'content',
    'messageTemplate',
    'receiver',
    'messageType',
    'displayDetail'
  ];

  static values = {
    url: String
  }

  toggleReplyButton(event) {
    const { value } = event.currentTarget.dataset;

    if(this.hasMessageTemplateTarget && this.messageTemplateTarget.value != '') { return; }

    this.toggleClass(this.replyButtonTargets, value, 'message__unselected-icon');
    this.toggleClass(this.displayDetailTargets, value, 'd-none');

    this.messageTypeTarget.value = value;
  }

  applyTemplate() {
    let link = new URL(this.urlValue)
    let searchParams = link.searchParams;
    searchParams.append('message_template_id', parseInt(this.messageTemplateTarget.value) || '');
    if (this.hasReceiverTarget) {
      searchParams.append('user_id', parseInt(this.receiverTarget.value) || '');
    }
    link.search = searchParams.toString();
    let url = link.toString();

    if (!url) { return; }

    ajax({
      type: 'POST',
      url: url,
      dataType: 'SCRIPT',
    })
  }

  toggleClass(targets, value, klass) {
    targets.forEach((element) => {
      if (element.dataset.value == value) {
        element.classList.remove(klass);
      } else {
        element.classList.add(klass);
      }
    })
  }
}
