import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["roleSelect", "checkoutForm"];

  updateCheckoutParams() {
    const form = this.checkoutFormTarget.closest("form");

    if(form) {
      const hiddenInput = form.querySelector('input[name="products[][data][role_id]"]');

      if (hiddenInput) {
        hiddenInput.value = this.roleSelectTarget.value;
      }
    }
  }
}