import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['media'];

  connect() {
    var that = this

    document.addEventListener("click", function(e) {
      if (!that.element.contains(e.target)){
        that.stopMedia()
      }
    })
  }

  stopMedia() {
    if (this.mediaTarget) {
      if (this.mediaTarget.tagName.toLowerCase() === 'video' && typeof this.mediaTarget.pause === 'function') {
        this.mediaTarget.pause();
      }

      if (this.mediaTarget.tagName.toLowerCase() === 'iframe') {
        this.mediaTarget.src = '';
      }
    }
  }
}
