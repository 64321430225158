import { Controller } from 'stimulus';

export default class extends Controller {
  toggleSelect(event) {
    const { allSelected, parentDropdown } = event.target.dataset;
    const toggleLinkText = { true: 'Unselect all', false: 'Select all' };
    const target = document.querySelector(`#${parentDropdown}`);
    const selectedValues = [...target.options].map((o) => o.value);
    const options = allSelected === 'true' ? selectedValues : [];

    target.slim.set(options);
    event.target.innerHTML = toggleLinkText[allSelected];
    event.target.setAttribute('data-all-selected', allSelected !== 'true');
  }
}
