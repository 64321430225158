import { ajax } from '@rails/ujs';
import CollapsibleController from './collapsible_controller';
import { prepareJSONRequest } from '../lib/utils';

export default class extends CollapsibleController {
  static values = {
    key: String,
    settingsPath: String
  };

  get collapsedClassName() {
    return 'widget--collapsed';
  }

  get settings() {
    return {
      user_settings: {
        widget_settings: {
          [this.keyValue]: { collapsed: this.isCollapsed }
        }
      }
    };
  }

  toggle(e) {
    super.toggle(e);
    this.updateSettings();
  }

  updateSettings() {
    ajax({
      type: 'PATCH',
      url: this.settingsPathValue,
      data: this.settings,
      dataType: 'json',
      beforeSend: prepareJSONRequest
    });
  }
}
