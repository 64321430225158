import { Controller } from 'stimulus';
import { ajax } from '@rails/ujs';

export default class extends Controller {
  static targets = [ "tagSelectbox" ]

  static values = {
    url: String
  }

  addNewTag(event) {
    if(!this.urlValue) { return; }

    let slimSelect = this.tagSelectboxTarget.slim
    const data = new FormData();
    data.append("tag[name]", event.detail);

    ajax({
      type: 'POST',
      url: this.urlValue,
      data,
      success: (res) => {
        if(!res.success) { return; }

        slimSelect.data.data.push(
          { text: res.tag.name, value: res.tag.id, selected: true }
        )
        slimSelect.setData(slimSelect.data.data)
        slimSelect.render()
        slimSelect.close()
      }
    });
  }
}
