import { Controller } from 'stimulus';

export default class extends Controller {
  static values = {
    conversationId: String
  };

  connect() {
    var link = document.getElementById(`conversation-${this.conversationIdValue}`);

    if (link == null)
      return

    link.click();
  }
}
