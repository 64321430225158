import { Controller } from 'stimulus';
import MicroModal from 'micromodal';
import { ajax } from '@rails/ujs';

export default class extends Controller {
  static values = {
    showOnLoad: Boolean
  };

  get contentElement() {
    return this.element.querySelector('.modal__container');
  }

  connect() {
    this.element.modal = this;
  }

  initialize() {
    if (this.showOnLoadValue) {
      this.show();
    }
  }

  get id() {
    return this.element.id;
  }

  show(options = {}) {
    if (options.url) { this.updateContent(options.url); }

    MicroModal.show(this.id, { disableFocus: true, ...options });
  }

  // Alternatively you can use the data-micromodal-close data attribute
  //
  close() {
    MicroModal.close(this.id);
  }

  updateContent(url) {
    if (!url) { return; }

    this.resetContent();

    ajax({
      type: 'GET',
      url,
      dataType: 'script'
    });
  }

  resetContent() {
    this.contentElement.innerHTML = '<div class="loader"><div class="spinner"></div></div>';
  }
}
