import { Controller } from 'stimulus';

export default class extends Controller {
  static values = {
    accessible: String
  };
  static targets = [
    'enrollButton',
    'checkoutButton'
  ];

  connect() {
    this.toggleCheckoutButton();
  }

  toggleCheckoutButton() {
    if (this.accessibleValue == 'true') {
      this.enrollButtonTarget.classList.add('d-block')
      this.checkoutButtonTarget.classList.add('d-none')
    } else {
      this.enrollButtonTarget.classList.add('d-none')
      this.checkoutButtonTarget.classList.add('d-block')
    }
  }
}
