import { Controller } from 'stimulus';

export default class extends Controller {
  initialize() {
    this.element.querySelectorAll('action-text-attachment').forEach((e) => {
      e.closest('div').classList.add('image');
    });
    this.element.querySelectorAll('.trix-content > div > br:only-child').forEach((e) => {
      e.closest('div').remove();
    });
  }
}
