import { Controller } from 'stimulus';
import { ajax } from '@rails/ujs';

export default class extends Controller {
  static values = {
    signedId: String
  };

  static targets = [
    'buttonIcon',
    'buttonText'
  ];

  delete(e) {
    e.preventDefault();
    this.toggle(true);
    ajax({
      type: 'DELETE',
      url: `/attachments/${this.signedIdValue}`,
      dataType: 'json',
      success: () => { this.element.remove(); },
      error: () => { this.toggle(false); }
    });
  }

  toggle(processing) {
    if (processing === true) {
      this.buttonIconTarget.classList.remove('fa-times-circle');
      this.buttonIconTarget.classList.add('fa-cog', 'fa-spin');
      this.buttonTextTarget.innerHTML = 'Processing...';
    } else {
      this.buttonIconTarget.classList.remove('fa-cog', 'fa-spin');
      this.buttonIconTarget.classList.add('fa-times-circle');
      this.buttonTextTarget.innerHTML = 'Please try that again';
    }
  }
}
