/* globals Turbolinks */
import { Controller } from 'stimulus';
import { ajax } from '@rails/ujs';

export default class extends Controller {
  static values = {
    url: String,
    key: String,
    value: String
  };

  refreshPage() {
    Turbolinks.visit(window.location.toString());
  }

  checkStatus() {
    ajax({
      type: 'GET',
      url: this.urlValue,
      dataType: 'json',
      success: (data) => {
        if (data[this.keyValue] !== this.valueValue) {
          this.refreshPage();
        }
      }
    });
  }

  initialize() {
    this.intervalId = setInterval(this.checkStatus.bind(this), 5000);
  }

  disconnect() {
    clearInterval(this.intervalId);
  }
}
