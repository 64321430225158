import { Controller } from 'stimulus';
import { fire } from '@rails/ujs';
import debounce from 'just-debounce-it';

export default class extends Controller {
  static values = {
    delay: Boolean
  };

  submitForm = () => {
    if (this.isDisabled) { return; }

    this.disableForm();
    fire(this.element, 'submit');
  };

  submitFormDelayed = debounce(
    this.submitForm,
    200
  );

  disableForm = () => {
    this.element.classList.add('form--disabled');
  };

  get isDisabled() {
    return this.element.classList.contains('form--disabled');
  }

  get isDelayed() {
    return this.delayValue;
  }

  get onChange() {
    return this.isDelayed ? this.submitFormDelayed : this.submitForm;
  }

  connect() {
    this.element.addEventListener('change', this.onChange);
    this.element.addEventListener('ajax:beforeSend', this.disableForm);
  }

  disconnect() {
    this.element.removeEventListener('change', this.onChange);
    this.element.removeEventListener('ajax:beforeSend', this.disableForm);
  }
}
