import { Controller } from 'stimulus';

export default class extends Controller {
  initialize() {
    this.element.querySelectorAll('.sc-quote').forEach((e) => {
      e.insertAdjacentHTML(
        'beforebegin',
        '<div class="message__quote-toggle" data-action="click->conversation#toggle"></div>'
      );
    });
  }

  toggle(e) {
    const quotedResponse = e.currentTarget.nextElementSibling;
    if (quotedResponse.classList.contains('sc-quote')) {
      quotedResponse.classList.toggle('sc-quote--visible');
    }
  }
}
