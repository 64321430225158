import { Controller } from 'stimulus';
import { ajax } from '@rails/ujs';

export default class extends Controller {
  static targets = ['ecommerceButton'];

  static values = {
    url: String
  }

  update() {
    const data = new FormData();

    if (this.urlValue) {
      data.append('sub_account[sub_account_ecommerce_enabled]', this.ecommerceButtonTarget.checked);
    }

    ajax({
      url: this.urlValue,
      type: "PATCH",
      dataType: "script",
      data
    });
  }
}
