// /* globals FontAwesome */
// import 'font-awesome-pro/js/all';

import { library, dom, config } from 'font-awesome-pro/js-packages/@fortawesome/fontawesome-svg-core';
import { faAddressCard } from 'font-awesome-pro/js-packages/@fortawesome/pro-light-svg-icons/faAddressCard';
import { faAnalytics } from 'font-awesome-pro/js-packages/@fortawesome/pro-light-svg-icons/faAnalytics';
import { faAngleDoubleLeft } from 'font-awesome-pro/js-packages/@fortawesome/pro-light-svg-icons/faAngleDoubleLeft';
import { faAngleDoubleRight } from 'font-awesome-pro/js-packages/@fortawesome/pro-light-svg-icons/faAngleDoubleRight';
import { faArrowLeft } from 'font-awesome-pro/js-packages/@fortawesome/pro-light-svg-icons/faArrowLeft';
import { faArrowRight } from 'font-awesome-pro/js-packages/@fortawesome/pro-regular-svg-icons/faArrowRight';
import { faArrowRight as faArrowRightLight } from 'font-awesome-pro/js-packages/@fortawesome/pro-light-svg-icons/faArrowRight';
import { faArrowToBottom } from 'font-awesome-pro/js-packages/@fortawesome/pro-light-svg-icons/faArrowToBottom';
import { faBell } from 'font-awesome-pro/js-packages/@fortawesome/pro-light-svg-icons/faBell';
import { faBolt } from 'font-awesome-pro/js-packages/@fortawesome/pro-light-svg-icons/faBolt';
import { faBooks } from 'font-awesome-pro/js-packages/@fortawesome/pro-light-svg-icons/faBooks';
import { faBoxUsd } from 'font-awesome-pro/js-packages/@fortawesome/pro-solid-svg-icons/faBoxUsd';
import { faBriefcase } from 'font-awesome-pro/js-packages/@fortawesome/pro-regular-svg-icons/faBriefcase';
import { faBriefcase as faBriefcaseLight } from 'font-awesome-pro/js-packages/@fortawesome/pro-light-svg-icons/faBriefcase';
import { faBullseyeArrow } from 'font-awesome-pro/js-packages/@fortawesome/pro-light-svg-icons/faBullseyeArrow';
import { faCalendar } from 'font-awesome-pro/js-packages/@fortawesome/pro-light-svg-icons/faCalendar';
import { faCaretDown } from 'font-awesome-pro/js-packages/@fortawesome/pro-solid-svg-icons/faCaretDown';
import { faCaretRight } from 'font-awesome-pro/js-packages/@fortawesome/pro-solid-svg-icons/faCaretRight';
import { faCartPlus } from 'font-awesome-pro/js-packages/@fortawesome/pro-light-svg-icons/faCartPlus';
import { faCertificate } from 'font-awesome-pro/js-packages/@fortawesome/pro-solid-svg-icons/faCertificate';
import { faCertificate as faCertificateLight } from 'font-awesome-pro/js-packages/@fortawesome/pro-light-svg-icons/faCertificate';
import { faCheck } from 'font-awesome-pro/js-packages/@fortawesome/pro-regular-svg-icons/faCheck';
import { faCheck as faCheckLight } from 'font-awesome-pro/js-packages/@fortawesome/pro-light-svg-icons/faCheck';
import { faCheckCircle } from 'font-awesome-pro/js-packages/@fortawesome/pro-solid-svg-icons/faCheckCircle';
import { faCheckCircle as faCheckCircleLight } from 'font-awesome-pro/js-packages/@fortawesome/pro-light-svg-icons/faCheckCircle';
import { faCheckSquare } from 'font-awesome-pro/js-packages/@fortawesome/pro-light-svg-icons/faCheckSquare';
import { faChevronDown } from 'font-awesome-pro/js-packages/@fortawesome/pro-light-svg-icons/faChevronDown';
import { faChevronLeft } from 'font-awesome-pro/js-packages/@fortawesome/pro-light-svg-icons/faChevronLeft';
import { faChevronRight } from 'font-awesome-pro/js-packages/@fortawesome/pro-light-svg-icons/faChevronRight';
import { faCircle } from 'font-awesome-pro/js-packages/@fortawesome/pro-solid-svg-icons/faCircle';
import { faCircle as faCircleLight } from 'font-awesome-pro/js-packages/@fortawesome/pro-light-svg-icons/faCircle';
import { faClipboardCheck } from 'font-awesome-pro/js-packages/@fortawesome/pro-light-svg-icons/faClipboardCheck';
import { faClipboardList } from 'font-awesome-pro/js-packages/@fortawesome/pro-light-svg-icons/faClipboardList';
import { faClock } from 'font-awesome-pro/js-packages/@fortawesome/pro-regular-svg-icons/faClock';
import { faClock as faClockLight } from 'font-awesome-pro/js-packages/@fortawesome/pro-light-svg-icons/faClock';
import { faCog } from 'font-awesome-pro/js-packages/@fortawesome/pro-solid-svg-icons/faCog';
import { faCog as faCogLight } from 'font-awesome-pro/js-packages/@fortawesome/pro-light-svg-icons/faCog';
import { faCommentAltLines } from 'font-awesome-pro/js-packages/@fortawesome/pro-light-svg-icons/faCommentAltLines';
import { faEnvelopeOpenText } from 'font-awesome-pro/js-packages/@fortawesome/pro-light-svg-icons/faEnvelopeOpenText';
import { faReply } from 'font-awesome-pro/js-packages/@fortawesome/pro-solid-svg-icons/faReply';
import { faCopy } from 'font-awesome-pro/js-packages/@fortawesome/pro-light-svg-icons/faCopy';
import { faCreditCard } from 'font-awesome-pro/js-packages/@fortawesome/pro-solid-svg-icons/faCreditCard';
import { faCreditCard as faCreditCardLight } from 'font-awesome-pro/js-packages/@fortawesome/pro-light-svg-icons/faCreditCard';
import { faDollarSign } from 'font-awesome-pro/js-packages/@fortawesome/pro-regular-svg-icons/faDollarSign';
import { faEdit } from 'font-awesome-pro/js-packages/@fortawesome/pro-regular-svg-icons/faEdit';
import { faEdit as faEditLight } from 'font-awesome-pro/js-packages/@fortawesome/pro-light-svg-icons/faEdit';
import { faEllipsisV } from 'font-awesome-pro/js-packages/@fortawesome/pro-regular-svg-icons/faEllipsisV';
import { faExclamationTriangle } from 'font-awesome-pro/js-packages/@fortawesome/pro-light-svg-icons/faExclamationTriangle';
import { faEye } from 'font-awesome-pro/js-packages/@fortawesome/pro-duotone-svg-icons/faEye';
import { faEye as faEyeRegular } from 'font-awesome-pro/js-packages/@fortawesome/pro-regular-svg-icons/faEye';
import { faEyeSlash } from 'font-awesome-pro/js-packages/@fortawesome/pro-duotone-svg-icons/faEyeSlash';
import { faFacebookSquare } from 'font-awesome-pro/js-packages/@fortawesome/pro-brands-svg-icons/faFacebookSquare';
import { faFileAlt } from 'font-awesome-pro/js-packages/@fortawesome/pro-light-svg-icons/faFileAlt';
import { faFileInvoice } from 'font-awesome-pro/js-packages/@fortawesome/pro-light-svg-icons/faFileInvoice';
import { faFolderOpen } from 'font-awesome-pro/js-packages/@fortawesome/pro-light-svg-icons/faFolderOpen';
import { faFolders } from 'font-awesome-pro/js-packages/@fortawesome/pro-solid-svg-icons/faFolders';
import { faForward } from 'font-awesome-pro/js-packages/@fortawesome/pro-light-svg-icons/faForward';
import { faGlobe } from 'font-awesome-pro/js-packages/@fortawesome/pro-light-svg-icons/faGlobe';
import { faGraduationCap } from 'font-awesome-pro/js-packages/@fortawesome/pro-light-svg-icons/faGraduationCap';
import { faGraduationCap as faGraduationCapDuo } from 'font-awesome-pro/js-packages/@fortawesome/pro-duotone-svg-icons/faGraduationCap';
import { faGripVertical } from 'font-awesome-pro/js-packages/@fortawesome/pro-solid-svg-icons/faGripVertical';
import { faHashtag } from 'font-awesome-pro/js-packages/@fortawesome/pro-regular-svg-icons/faHashtag';
import { faHome } from 'font-awesome-pro/js-packages/@fortawesome/pro-light-svg-icons/faHome';
import { faHorizontalRule } from 'font-awesome-pro/js-packages/@fortawesome/pro-light-svg-icons/faHorizontalRule';
import { faHourglass } from 'font-awesome-pro/js-packages/@fortawesome/pro-light-svg-icons/faHourglass';
import { faInfoCircle } from 'font-awesome-pro/js-packages/@fortawesome/pro-solid-svg-icons/faInfoCircle';
import { faInfoCircle as faInfoCircleLight } from 'font-awesome-pro/js-packages/@fortawesome/pro-light-svg-icons/faInfoCircle';
import { faKey } from 'font-awesome-pro/js-packages/@fortawesome/pro-light-svg-icons/faKey';
import { faKey as faKeyDuo } from 'font-awesome-pro/js-packages/@fortawesome/pro-duotone-svg-icons/faKey';
import { faKeyboard } from 'font-awesome-pro/js-packages/@fortawesome/pro-light-svg-icons/faKeyboard';
import { faList } from 'font-awesome-pro/js-packages/@fortawesome/pro-light-svg-icons/faList';
import { faLockAlt } from 'font-awesome-pro/js-packages/@fortawesome/pro-solid-svg-icons/faLockAlt';
import { faLockAlt as faLockAltLight } from 'font-awesome-pro/js-packages/@fortawesome/pro-light-svg-icons/faLockAlt';
import { faMailBulk } from 'font-awesome-pro/js-packages/@fortawesome/pro-light-svg-icons/faMailBulk';
import { faMapMarkerAlt } from 'font-awesome-pro/js-packages/@fortawesome/pro-regular-svg-icons/faMapMarkerAlt';
import { faMapMarkerAlt as faMapMarkerAltLight } from 'font-awesome-pro/js-packages/@fortawesome/pro-light-svg-icons/faMapMarkerAlt';
import { faMedkit } from 'font-awesome-pro/js-packages/@fortawesome/pro-regular-svg-icons/faMedkit';
import { faMicrophone } from 'font-awesome-pro/js-packages/@fortawesome/pro-solid-svg-icons/faMicrophone';
import { faNotesMedical } from 'font-awesome-pro/js-packages/@fortawesome/pro-light-svg-icons/faNotesMedical';
import { faPaperclip } from 'font-awesome-pro/js-packages/@fortawesome/pro-light-svg-icons/faPaperclip';
import { faPaperPlane } from 'font-awesome-pro/js-packages/@fortawesome/pro-light-svg-icons/faPaperPlane';
import { faPencilRuler } from 'font-awesome-pro/js-packages/@fortawesome/pro-light-svg-icons/faPencilRuler';
import { faPenSquare } from 'font-awesome-pro/js-packages/@fortawesome/pro-solid-svg-icons/faPenSquare';
import { faPlus } from 'font-awesome-pro/js-packages/@fortawesome/pro-solid-svg-icons/faPlus';
import { faPlus as faPlusLight } from 'font-awesome-pro/js-packages/@fortawesome/pro-light-svg-icons/faPlus';
import { faPlusCircle } from 'font-awesome-pro/js-packages/@fortawesome/pro-solid-svg-icons/faPlusCircle';
import { faPlusCircle as faPlusCircleLight } from 'font-awesome-pro/js-packages/@fortawesome/pro-light-svg-icons/faPlusCircle';
import { faRss } from 'font-awesome-pro/js-packages/@fortawesome/pro-light-svg-icons/faRss';
import { faRssSquare } from 'font-awesome-pro/js-packages/@fortawesome/pro-solid-svg-icons/faRssSquare';
import { faSearch } from 'font-awesome-pro/js-packages/@fortawesome/pro-light-svg-icons/faSearch';
import { faShare } from 'font-awesome-pro/js-packages/@fortawesome/pro-light-svg-icons/faShare';
import { faShoppingCart } from 'font-awesome-pro/js-packages/@fortawesome/pro-solid-svg-icons/faShoppingCart';
import { faShoppingCart as faShoppingCartLight } from 'font-awesome-pro/js-packages/@fortawesome/pro-light-svg-icons/faShoppingCart';
import { faSitemap } from 'font-awesome-pro/js-packages/@fortawesome/pro-solid-svg-icons/faSitemap';
import { faSitemap as faSitemapLight } from 'font-awesome-pro/js-packages/@fortawesome/pro-light-svg-icons/faSitemap';
import { faSort } from 'font-awesome-pro/js-packages/@fortawesome/pro-solid-svg-icons/faSort';
import { faSort as faSortDuo } from 'font-awesome-pro/js-packages/@fortawesome/pro-duotone-svg-icons/faSort';
import { faSortUp } from 'font-awesome-pro/js-packages/@fortawesome/pro-duotone-svg-icons/faSortUp';
import { faSortDown } from 'font-awesome-pro/js-packages/@fortawesome/pro-duotone-svg-icons/faSortDown';
import { faStar } from 'font-awesome-pro/js-packages/@fortawesome/pro-solid-svg-icons/faStar';
import { faStar as faStarLight } from 'font-awesome-pro/js-packages/@fortawesome/pro-light-svg-icons/faStar';
import { faTasks } from 'font-awesome-pro/js-packages/@fortawesome/pro-light-svg-icons/faTasks';
import { faThLarge } from 'font-awesome-pro/js-packages/@fortawesome/pro-solid-svg-icons/faThLarge';
import { faThLarge as faThLargeLight } from 'font-awesome-pro/js-packages/@fortawesome/pro-light-svg-icons/faThLarge';
import { faTimes } from 'font-awesome-pro/js-packages/@fortawesome/pro-light-svg-icons/faTimes';
import { faTimesCircle } from 'font-awesome-pro/js-packages/@fortawesome/pro-solid-svg-icons/faTimesCircle';
import { faTrashAlt } from 'font-awesome-pro/js-packages/@fortawesome/pro-regular-svg-icons/faTrashAlt';
import { faTrashAlt as faTrashAltLight } from 'font-awesome-pro/js-packages/@fortawesome/pro-light-svg-icons/faTrashAlt';
import { faTwitterSquare } from 'font-awesome-pro/js-packages/@fortawesome/pro-brands-svg-icons/faTwitterSquare';
import { faUnderline } from 'font-awesome-pro/js-packages/@fortawesome/pro-regular-svg-icons/faUnderline';
import { faUniversity } from 'font-awesome-pro/js-packages/@fortawesome/pro-light-svg-icons/faUniversity';
import { faUser } from 'font-awesome-pro/js-packages/@fortawesome/pro-solid-svg-icons/faUser';
import { faUser as faUserLight } from 'font-awesome-pro/js-packages/@fortawesome/pro-light-svg-icons/faUser';
import { faUsers } from 'font-awesome-pro/js-packages/@fortawesome/pro-solid-svg-icons/faUsers';
import { faUsers as faUsersLight } from 'font-awesome-pro/js-packages/@fortawesome/pro-light-svg-icons/faUsers';
import { faUserMd } from 'font-awesome-pro/js-packages/@fortawesome/pro-regular-svg-icons/faUserMd';
import { faUserMd as faUserMdLight } from 'font-awesome-pro/js-packages/@fortawesome/pro-light-svg-icons/faUserMd';
import { faUserMinus } from 'font-awesome-pro/js-packages/@fortawesome/pro-light-svg-icons/faUserMinus';
import { faUserPlus } from 'font-awesome-pro/js-packages/@fortawesome/pro-light-svg-icons/faUserPlus';
import { faUserTimes } from 'font-awesome-pro/js-packages/@fortawesome/pro-light-svg-icons/faUserTimes';
import { faVolume } from 'font-awesome-pro/js-packages/@fortawesome/pro-solid-svg-icons/faVolume';
import { faWindowRestore } from 'font-awesome-pro/js-packages/@fortawesome/pro-light-svg-icons/faWindowRestore';
import { faWrench } from 'font-awesome-pro/js-packages/@fortawesome/pro-light-svg-icons/faWrench';
import { faUserCircle } from 'font-awesome-pro/js-packages/@fortawesome/pro-light-svg-icons/faUserCircle';
import { faBars } from 'font-awesome-pro/js-packages/@fortawesome/pro-light-svg-icons/faBars';
import { faUndo } from 'font-awesome-pro/js-packages/@fortawesome/pro-light-svg-icons/faUndo';
import { faHourglassHalf } from 'font-awesome-pro/js-packages/@fortawesome/pro-light-svg-icons/faHourglassHalf';
import { faListUl } from 'font-awesome-pro/js-packages/@fortawesome/pro-light-svg-icons/faListUl';
import { faTrophyAlt } from 'font-awesome-pro/js-packages/@fortawesome/pro-light-svg-icons/faTrophyAlt';
import { faVideo } from 'font-awesome-pro/js-packages/@fortawesome/pro-light-svg-icons/faVideo';
import { faRepeat } from 'font-awesome-pro/js-packages/@fortawesome/pro-light-svg-icons/faRepeat';
import { faBookmark } from 'font-awesome-pro/js-packages/@fortawesome/pro-light-svg-icons/faBookmark';
import { faDownload } from 'font-awesome-pro/js-packages/@fortawesome/pro-light-svg-icons/faDownload';
import { faAtlas } from 'font-awesome-pro/js-packages/@fortawesome/pro-light-svg-icons/faAtlas';
import { faBolt as faBoltSolid } from 'font-awesome-pro/js-packages/@fortawesome/pro-solid-svg-icons/faBolt';
import { faReplyAll } from 'font-awesome-pro/js-packages/@fortawesome/pro-solid-svg-icons/faReplyAll';
import { faFileSearch } from 'font-awesome-pro/js-packages/@fortawesome/pro-light-svg-icons/faFileSearch';
import { faFilePdf } from 'font-awesome-pro/js-packages/@fortawesome/pro-light-svg-icons/faFilePdf';
import { faFileCode } from 'font-awesome-pro/js-packages/@fortawesome/pro-light-svg-icons/faFileCode';
import { faMusic } from 'font-awesome-pro/js-packages/@fortawesome/pro-light-svg-icons/faMusic';
import { faQuestionCircle } from 'font-awesome-pro/js-packages/@fortawesome/pro-light-svg-icons/faQuestionCircle';
import { faExternalLink } from 'font-awesome-pro/js-packages/@fortawesome/pro-solid-svg-icons/faExternalLink';
import { faChevronDoubleRight } from 'font-awesome-pro/js-packages/@fortawesome/pro-solid-svg-icons/faChevronDoubleRight';
import { faChevronDoubleLeft } from 'font-awesome-pro/js-packages/@fortawesome/pro-solid-svg-icons/faChevronDoubleLeft';
import { faExclamationCircle } from 'font-awesome-pro/js-packages/@fortawesome/pro-solid-svg-icons/faExclamationCircle';
import { faUpload } from 'font-awesome-pro/js-packages/@fortawesome/pro-solid-svg-icons/faUpload';
import { faMinusCircle } from 'font-awesome-pro/js-packages/@fortawesome/pro-solid-svg-icons/faMinusCircle';
import { faBullhorn as faBullhornLight } from 'font-awesome-pro/js-packages/@fortawesome/pro-light-svg-icons/faBullhorn';
import { faUserCog } from 'font-awesome-pro/js-packages/@fortawesome/pro-solid-svg-icons/faUserCog';
import { faUserCog as faUserCogLight } from 'font-awesome-pro/js-packages/@fortawesome/pro-light-svg-icons/faUserCog';

library.add(
  faAddressCard,
  faAnalytics,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faArrowLeft,
  faArrowRight,
  faArrowRightLight,
  faArrowToBottom,
  faBell,
  faBolt,
  faBooks,
  faBoxUsd,
  faBriefcase,
  faBriefcaseLight,
  faBullseyeArrow,
  faCalendar,
  faCaretDown,
  faCaretRight,
  faCartPlus,
  faCertificate,
  faCertificateLight,
  faCheck,
  faCheckLight,
  faCheckCircle,
  faCheckCircleLight,
  faCheckSquare,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faCircle,
  faCircleLight,
  faClipboardCheck,
  faClipboardList,
  faClock,
  faClockLight,
  faCog,
  faCogLight,
  faCommentAltLines,
  faEnvelopeOpenText,
  faReply,
  faCopy,
  faCreditCard,
  faCreditCardLight,
  faDollarSign,
  faEdit,
  faEditLight,
  faEllipsisV,
  faExclamationTriangle,
  faEye,
  faEyeRegular,
  faEyeSlash,
  faFacebookSquare,
  faFileAlt,
  faFileInvoice,
  faFolderOpen,
  faFolders,
  faForward,
  faGlobe,
  faGraduationCap,
  faGraduationCapDuo,
  faGripVertical,
  faHashtag,
  faHome,
  faHourglass,
  faHorizontalRule,
  faInfoCircle,
  faInfoCircleLight,
  faKey,
  faKeyDuo,
  faKeyboard,
  faList,
  faLockAlt,
  faLockAltLight,
  faMailBulk,
  faMapMarkerAlt,
  faMapMarkerAltLight,
  faMedkit,
  faMicrophone,
  faNotesMedical,
  faPaperclip,
  faPaperPlane,
  faPencilRuler,
  faPenSquare,
  faPlus,
  faPlusLight,
  faPlusCircle,
  faPlusCircleLight,
  faRss,
  faRssSquare,
  faSearch,
  faShare,
  faShoppingCart,
  faShoppingCartLight,
  faSitemap,
  faSitemapLight,
  faSort,
  faSortDuo,
  faSortUp,
  faSortDown,
  faStar,
  faStarLight,
  faTasks,
  faThLarge,
  faThLargeLight,
  faTimes,
  faTimesCircle,
  faTrashAlt,
  faTrashAltLight,
  faTwitterSquare,
  faUnderline,
  faUniversity,
  faUser,
  faUserLight,
  faUsers,
  faUsersLight,
  faUserMd,
  faUserMdLight,
  faUserMinus,
  faUserPlus,
  faUserTimes,
  faVolume,
  faWindowRestore,
  faWrench,
  faUserCircle,
  faBars,
  faUndo,
  faHourglassHalf,
  faListUl,
  faTrophyAlt,
  faVideo,
  faRepeat,
  faBookmark,
  faDownload,
  faUpload,
  faAtlas,
  faBoltSolid,
  faReplyAll,
  faFileSearch,
  faFilePdf,
  faFileCode,
  faMusic,
  faQuestionCircle,
  faExternalLink,
  faChevronDoubleRight,
  faChevronDoubleLeft,
  faExclamationCircle,
  faMinusCircle,
  faBullhornLight,
  faUserCog,
  faUserCogLight,
);

// https://fontawesome.com/how-to-use/on-the-web/using-with/turbolinks
// FontAwesome.config.mutateApproach = 'sync';
config.mutateApproach = 'sync';

// Replace any existing <i> tags with <svg> and set up a MutationObserver to
// continue doing this as the DOM changes.
dom.watch();
