import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['videoToggle', 'customVideo', 'youtubeUrl'];

  static values = {
    persisted: String
  };

  connect() {
    this.DisplaycustomVideoAndHideyoutubeUrl();
  }

  DisplaycustomVideoAndHideyoutubeUrl() {
    if(this.videoToggleTarget.checked) {
      this.customVideoTarget.classList.add('d-none');
      this.youtubeUrlTarget.classList.remove('d-none');
    } else {
      this.customVideoTarget.classList.remove('d-none');
      this.youtubeUrlTarget.classList.add('d-none');
    }
  }

  clearInputField() {
    let containers = [this.customVideoTarget, this.youtubeUrlTarget]

    containers.forEach(container => {
      container.querySelector('input').value = '';
    })
  }
}
