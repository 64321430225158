import { Controller } from 'stimulus';
import MicroModal from 'micromodal';

export default class extends Controller {
  static targets = ['confirmationModal'];

  open () {
    MicroModal.show(this.confirmationModalTarget.id)
  }
}
