import { Controller } from 'stimulus';
import { ajax } from '@rails/ujs';

export default class extends Controller {
  static targets = ['icon'];

  load() {
    ajax({
      type: 'GET',
      dataType: 'script',
      url: '/notifications',
      success: () => { this.removePendingIndicator(); }
    });
  }

  removePendingIndicator() {
    this.iconTarget.classList.remove('notifications-icon--pending');
  }
}
