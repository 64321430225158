import { Controller } from 'stimulus';
import { ajax } from "@rails/ujs";

export default class extends Controller {
  static values = {
    url: String
  };

  update(e) {
    ajax({
      url: `${this.urlValue}?category=${e.currentTarget.value}`,
      type: "get",
      dataType: "script",
    });
  }
}
