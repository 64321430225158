import { Controller } from 'stimulus';
import tippy from 'tippy.js';

export default class extends Controller {
  static values = {
    text: String,
    placement: String,
    stayOpen: Boolean,
    zIndex: Number
  };

  get content() {
    return this.textValue;
  }

  get placement() {
    return this.placementValue || 'top';
  }

  initialize() {
    if (!this.content) { return; }

    tippy(this.element, {
      content: this.content,
      placement: this.placement,
      boundary: 'viewport',
      allowHTML: true,
      interactive: this.stayOpenValue,
      zIndex: this.zIndexValue !== 0 ? this.zIndexValue : 9999
    });
  }
}
