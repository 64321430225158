import { Controller } from 'stimulus';
import { ajax } from '@rails/ujs';
import { Notyf } from 'notyf';
import { config } from './notice_controller';

export default class extends Controller {
  static values = {
    id: Number,
    settingsUrl: String,
    sequenceId: Number,
    settingsScope: String
  };

  toggle(event) {
    const stage = event.currentTarget.closest('.sequence__stage');
    stage.classList.add('sequence__stage--transitioning');
    stage.classList.toggle('sequence__stage--collapsed');

    stage.addEventListener('transitionend', () => {
      stage.classList.remove('sequence__stage--transitioning');
    });
    this.saveState(stage);
  }

  saveState(stage) {
    const collapsed = stage.classList.contains('sequence__stage--collapsed');

    const data = new FormData();
    data.append(
      `user_settings[${this.settingsScopeValue}[${this.sequenceIdValue}][${this.idValue}][collapsed]]`,
      collapsed
    );

    ajax({
      type: 'PATCH',
      dataType: 'json',
      url: this.settingsUrlValue,
      data,
      error: (response) => {
        this.displayFlashMessage('error', response.error);
      }
    });
  }

  displayFlashMessage(type, content) {
    if (type && content) {
      new Notyf(config)[type](content);
    }
  }
}
