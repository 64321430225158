import { Controller } from 'stimulus';
import Turbolinks from 'turbolinks';
import { isMobile } from '../lib/utils';

export default class extends Controller {
  static values = {
    url: String
  };

  onClick(e) {
    if (isMobile()) {
      e.preventDefault();
      Turbolinks.scroll.top = null; // reset scroll position on mobile
      Turbolinks.visit(this.urlValue);
    }
  }
}
