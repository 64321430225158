import { Controller } from 'stimulus';

export default class extends Controller {
  get headerHeight() {
    return document.querySelector('.product-page__header').offsetHeight;
  }

  connect() {
    this.updatePosition();
  }

  updatePosition() {
    if (window.innerWidth > 800) {
      this.element.style.marginTop = `-${this.headerHeight - 24}px`;
    } else {
      this.element.style.marginTop = 0
    }
  }
}
