import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['actionCheckbox', 'allCheckbox'];

  selectAll(event) {
    this.actionCheckboxTargets.forEach((checkbox) => {
      checkbox.checked = event.target.checked && !checkbox.disabled;
    });
  }

  toggleAllCheckbox() {
    const allChecked = this.actionCheckboxTargets.every((checkbox) => (
      checkbox.checked || checkbox.disabled
    ));

    this.allCheckboxTarget.checked = allChecked;
  }
}
