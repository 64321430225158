import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['title', 'content', 'cancel', 'confirm'];

  static values = {
    sequenceAssignmentId: Number
  };

  get sequenceAssignmentController() {
    const element = document.querySelector(`[data-sequence-assignment-id-value="${this.sequenceAssignmentIdValue}"]`);
    return this.application.getControllerForElementAndIdentifier(element, 'sequence-assignment');
  }

  show(type) {
    this.updateContent(type);
    this.element.modal.show({
      onClose: this.onCancel.bind(this)
    });
  }

  updateContent(type) {
    this.type = type;
    if (type === 'confirm') {
      this.titleTarget.innerHTML = 'Are you sure?';
      this.contentTarget.innerHTML = '';
      this.confirmTarget.innerHTML = 'Continue';
      this.cancelTarget.innerHTML = 'Cancel';
      this.confirmTarget.classList.add('button--danger');
    } else {
      this.titleTarget.innerHTML = 'Restart Sequence?';
      this.contentTarget.innerHTML = 'Would you like to restart the sequence from this position?';
      this.confirmTarget.innerHTML = 'Yes';
      this.cancelTarget.innerHTML = 'No';
      this.confirmTarget.classList.remove('button--danger');
    }
  }

  onConfirm() {
    this._confirming = true;
    this.element.modal.close();
    this._confirming = false;
    this.sequenceAssignmentController.onRestartConfirm(this.type);
  }

  onCancel() {
    if (this._confirming) { return; }
    this.sequenceAssignmentController.onRestartCancel(this.type);
  }
}
