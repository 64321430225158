import { Controller } from 'stimulus';

export default class extends Controller {
  get collapsedClassName() {
    return 'collapsible-container--collapsed';
  }

  get isCollapsed() {
    return this.element.classList.contains(this.collapsedClassName);
  }

  toggle(e) {
    e.preventDefault();
    this.element.classList.toggle(this.collapsedClassName);
    this.element.blur();
  }

  expandAll(event) {
    event.preventDefault();
    this.element.querySelectorAll('.collapsible-container').forEach((e) => {
      e.classList.remove(this.collapsedClassName);
    });
  }

  collapseAll(event) {
    event.preventDefault();
    this.element.querySelectorAll('.collapsible-container').forEach((e) => {
      e.classList.add(this.collapsedClassName);
    });
  }
}
