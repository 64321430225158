import { Controller } from 'stimulus';
import SlimSelect from 'slim-select';

export default class extends Controller {
  static values = {
    addableSelect: Boolean,
    placeholder:  String,
    closeOnSelect: Boolean
  }

  connect() {
    this.slimSelect = new SlimSelect({
      select: this.element,
      closeOnSelect: this.closeOnSelectValue || false,
      placeholder: this.placeholderValue || 'Select',
      addable: (this.addableSelectValue) ? this.fireAddEvent.bind(this) : false
    })

    this.addOnEnter();
    this.addValidation();
  }

  disconnect() {
    this.element.slim.destroy();
  }

  fireAddEvent(event) {
    const e = new CustomEvent('slim-select.addable', { detail: event });
    this.element.dispatchEvent(e)
  }

  addOnEnter() {
    if(!this.addableSelectValue) { return; }

    this.element.parentElement.addEventListener('keydown', function(event) {
      if (event.key === 'Enter') {
        document.querySelector('.ss-addable').click();
      }
    })
  }

  addValidation() {
    const form = this.element.closest('form');
    let errorResponse = this.element.parentElement.querySelector('#slim-form-error');

    if (errorResponse) {
      form.addEventListener('submit', (event) => {
        if (this.slimSelect.selected().length == 0) {
          errorResponse.innerHTML = "Please select atleast one option.";
        }
      });
    }
  }
}
