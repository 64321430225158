import { Controller } from 'stimulus';
import { toggleElement } from '../lib/utils';

export default class extends Controller {
  static targets = ['toggle'];

  toggle(e) {
    if (e) { e.preventDefault(); }

    toggleElement(this.toggleTarget);
  }
}
