import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['featuresSection', 'parentAccountDropdown', 'subAccountPlanDropdown'];

  connect() {
    this.toggle();
  }

  toggle() {
    if (this.parentAccountDropdownTarget.selectedOptions[0].value != '') {
      this.featuresSectionTarget.classList.remove('d-none');
      this.subAccountPlanDropdownTarget.classList.add('d-none');
    } else {
      this.featuresSectionTarget.classList.add('d-none');
      this.subAccountPlanDropdownTarget.classList.remove('d-none');
    }
  }
}
