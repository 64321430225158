// Modified version of https://github.com/jorgemanrubia/rails-form-validations-example/blob/master/app/javascript/controllers/form_controller.js
import { Controller } from 'stimulus';
import { isRichTextField, isRequiredField } from '../lib/utils';

export default class extends Controller {
  onSubmit = (e) => {
    if (!this.validateForm()) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  get isRemote() {
    return this.element.dataset.remote === 'true';
  }

  get isPost() {
    return this.element.method === 'post';
  }

  get formFields() {
    if (!this._formFields) {
      const formElements = Array.from(this.element.elements);
      const richTextElements = Array.from(this.element.querySelectorAll('trix-editor'));
      this._formFields = [...formElements, ...richTextElements];
    }

    return this._formFields;
  }

  connect() {
    this.element.setAttribute('novalidate', true);
    if (this.isRemote && this.isPost) {
      this.element.addEventListener('ajax:beforeSend', this.onSubmit);
    } else {
      this.element.addEventListener('submit', this.onSubmit);
    }
  }

  disconnect() {
    if (this.isRemote && this.isPost) {
      this.element.removeEventListener('ajax:beforeSend', this.onSubmit);
    } else {
      this.element.removeEventListener('submit', this.onSubmit);
    }
  }

  validateForm() {
    let isValid = true;
    this.formFields.forEach((field) => {
      if (this.shouldValidateField(field) && !this.validateField(field)) {
        isValid = false;
      }
    });
    this.element.classList.toggle('form--invalid', !isValid);
    return isValid;
  }

  shouldValidateField(field) {
    // ignore multi-select search box and trix dialogs
    if (field.classList.contains('search-input') || field.classList.contains('trix-input')) {
      return false;
    }
    return !field.disabled && !['hidden', 'reset', 'submit', 'button'].includes(field.type);
  }

  validateField(field) {
    if (!this.shouldValidateField(field)) { return true; }

    const fieldWrapper = field.closest('.form__field');
    let isValid;

    if (field.getAttribute('name')) {
      const fields = fieldWrapper.querySelectorAll(`[name="${field.name}"]`);
      isValid = this.checkValidityMultiple(fields);
    } else {
      isValid = this.checkValidity(field);
    }

    if (fieldWrapper) {
      fieldWrapper.classList.toggle('form__field--invalid', !isValid);
    }
    return isValid;
  }

  checkValidity(field) {
    if (isRichTextField(field)) {
      if (isRequiredField(field)) {
        return !!field.textContent;
      }
      return true;
    }

    if (!field.readOnly) {
      if (field.type === 'hidden') {
        return field.value !== '';
      }
      return field.checkValidity();
    }

    field.readOnly = false;
    const isValid = field.checkValidity();
    field.readOnly = true;

    return isValid;
  }

  checkValidityMultiple(fields) {
    return Array.from(fields).some(this.checkValidity);
  }
}
