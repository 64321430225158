import { Controller } from 'stimulus';
import Turbolinks from 'turbolinks';
import { isMobile } from '../lib/utils';

export default class extends Controller {
  static values = {
    ref: String
  };

  get selectedJob() {
    return this.element.querySelector('.job-card--selected');
  }

  initialize() {
    if (isMobile() && this.isShowPage()) {
      const url = this.selectedJob.dataset.jobCardUrlValue;
      Turbolinks.visit(url, { action: 'replace' });
    }
  }

  isShowPage() {
    if (!this.selectedJob) { return false; }

    return this.selectedJob.dataset.ref === this.refValue;
  }
}
