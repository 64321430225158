import { Controller } from 'stimulus';
import { fire } from '@rails/ujs';

export default class extends Controller {
  static values = {
    field: String,
    form: String
  };

  get field() {
    return document.querySelector(this.fieldValue);
  }

  get form() {
    return document.querySelector(this.formValue);
  }

  confirm() {
    this.field.value = true;
    fire(this.form, 'submit');
  }
}
