import { Controller } from 'stimulus';
import { ajax } from '@rails/ujs';
import { Notyf } from 'notyf';
import { config } from './notice_controller';

export default class extends Controller {
  static targets = ['submitFlag', 'formButton'];

  static values = {
    disable: Boolean,
    alllowFileUpload: Boolean,
    allowButtonsDisable: Boolean
  };

  get url() {
    return this.element.getAttribute('action');
  }

  get method() {
    return this.element.getAttribute('method') || 'POST';
  }

  get disabled() {
    return this.disableValue;
  }

  get formData() {
    const data = new FormData(this.element);
    if (this.alllowFileUploadValue) { return data; }

    Array.from(data).forEach((entry) => {
      if (entry[1] instanceof File) {
        data.delete(entry[0]);
      }
    });
    return data;
  }

  initialize() {
    if (this.disabled) { return; }

    this.intervalId = setInterval(this.saveForm.bind(this), 10000);
    this.element.addEventListener('submit', this.setSubmitFlag.bind(this));
  }

  disconnect() {
    clearInterval(this.intervalId);
  }

  saveForm(event) {
    const { element } = event.currentTarget.dataset;
    if (event.type == 'click') { this.toggleButtonsState(true); }

    ajax({
      type: this.method,
      url: this.url,
      dataType: 'json',
      data: this.formData,

      success: () => {
        if ((element != undefined) && (element == 'SaveForLater')) {
          this.displayToaster('success', 'Updated');
        }

        this.toggleButtonsState(false);
      },
      error: () => {
        this.toggleButtonsState(false);
        this.disconnect();
      }
    });
  }

  setSubmitFlag() {
    if (!this.hasSubmitFlagTarget) { return; }

    this.submitFlagTarget.value = 'true';
  }

  displayToaster(type, content) {
    if (type && content) {
      new Notyf(config)[type](content);
    }
  }

  toggleButtonsState(disable) {
    if(!this.allowButtonsDisableValue && this.formButtonTargets.length != 0 ) { return; }

    this.formButtonTargets.forEach((element) => {
      if (disable) {
        element.setAttribute('disabled', disable)
        element.setAttribute('aria-disabled', disable)
      } else {
        element.removeAttribute('disabled')
        element.removeAttribute('aria-disabled')
      }
    })
  }
}
