/* globals Stripe */
import { Controller } from 'stimulus';
import { ajax, enableElement } from '@rails/ujs';
import { addHiddenInput, prepareJSONRequest } from '../lib/utils';

const stripeElementsConfig = {
  classes: {
    base: 'form__input',
    complete: 'form__input--complete',
    empty: 'form__input--empty',
    focus: 'form__input--focus',
    invalid: 'form__input--invalid',
    webkitAutoFill: 'form__input--webkit-autofill'
  },

  style: {
    base: {
      color: '#222222', // $grey-100
      fontFamily: "'Lato', sans-serif",
      fontSmoothing: 'antialiased',
      fontSize: '12px',
      '::placeholder': {
        color: '#9E9E9E' // $grey-050
      },
      lineHeight: '15px'
    },
    invalid: {
      color: '#A72118', // $red-100
      iconColor: '#A72118' // $red-100
    }
  }
};

export default class extends Controller {
  static targets = ['form', 'card', 'errors', 'name', 'submit', 'paymentMethod', 'cardFields'];

  static values = {
    paymentIntentUrl: String,
    setupIntentUrl: String,
    stripeAccountId: String
  };

  get paymentMethod() {
    if (this.existingPaymentMethod) { return this.paymentMethodTarget.value; }

    return {
      card: this.card,
      billing_details: {
        name: this.nameTarget.value
      }
    };
  }

  get existingPaymentMethod() {
    return this.hasPaymentMethodTarget && this.paymentMethodTarget.value !== '';
  }

  get newPaymentMethod() {
    return !this.existingPaymentMethod;
  }

  initialize() {
    this.initializeStripe();
    this.initializeCard();
    this.updateForm();
  }

  initializeStripe() {
    const publicKey = document.querySelector("meta[name='stripe-key']").getAttribute('content');

    this.stripe = Stripe(publicKey);
    if (this.stripeAccountIdValue) {
      this.stripeConnect = Stripe(publicKey, { stripeAccount: this.stripeAccountIdValue });
    }
  }

  initializeCard() {
    this.card = this.stripe.elements().create('card', stripeElementsConfig);
    this.card.mount(this.cardTarget);
    this.card.addEventListener('change', (event) => this.updateErrors(event));
  }

  updateForm() {
    if (!this.hasPaymentMethodTarget) { return; }

    if (this.paymentMethodTarget.value === '') {
      this.cardFieldsTarget.style.display = 'block';
    } else {
      this.cardFieldsTarget.style.display = 'none';
    }
  }

  updateErrors(event) {
    if (event.error) {
      this.errorsTarget.textContent = event.error.message;
    } else {
      this.errorsTarget.textContent = '';
    }
  }

  /* This method fires when the form is submitted. This handles all cases where a
   * payment form can be used.
   */
  submit(e) {
    e.preventDefault();

    if (this.existingPaymentMethod) {
      this.submitPayment(this.paymentMethod);
      return;
    }

    ajax({
      type: 'POST',
      url: this.setupIntentUrlValue,
      dataType: 'json',
      success: (response) => {
        if (response.error) {
          this.updateErrors(response);
          enableElement(this.submitTarget);
        } else {
          this.setupPaymentMethod(response).then((paymentMethodId) => {
            if (!paymentMethodId) { return; }

            if (this.paymentIntentUrlValue) {
              this.submitPayment(paymentMethodId);
            } else {
              this.submitPaymentMethod(paymentMethodId);
            }
          });
        }
      }
    });
  }

  /* Updates the form with the newly setup card id and then submits the form.
   */
  submitPaymentMethod(paymentMethodId) {
    addHiddenInput('payment_method_id', paymentMethodId, this.formTarget);
    this.formTarget.submit();
  }

  /* Given a payment method this creates a payment intent and then confirms the
   * payment.
  */
  submitPayment(paymentMethodId) {
    const data = {
      stripe_payment_method_id: paymentMethodId
    };

    ajax({
      type: 'POST',
      url: this.paymentIntentUrlValue,
      dataType: 'json',
      data,
      beforeSend: prepareJSONRequest,
      success: (response) => {
        if (response.error) {
          this.updateErrors(response);
          enableElement(this.submitTarget);
        } else {
          this.confirmPayment(response);
        }
      }
    });
  }

  /* Given a payment intent this confirms the card payment on the client side using
   * Stripe. If everything goes well the payment form will be submitted.
   */
  confirmPayment(intent) {
    const stripe = (this.stripeConnect || this.stripe);
    return stripe.confirmCardPayment(intent.client_secret).then((result) => {
      if (result.error) {
        this.updateErrors(result);
        enableElement(this.submitTarget);
      } else {
        this.formTarget.submit();
      }
    });
  }

  /* Given a setup intent this confirms the card setup on the client side using
   * Stripe. If everything goes well the new card id will be returned;
   */
  setupPaymentMethod(intent) {
    const data = {
      payment_method: this.paymentMethod
    };

    return this.stripe.confirmCardSetup(intent.client_secret, data).then((result) => {
      if (result.error) {
        this.updateErrors(result);
        enableElement(this.submitTarget);
        return false;
      }

      return result.setupIntent.payment_method;
    });
  }
}
