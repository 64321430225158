import { Controller } from 'stimulus';
import { fire } from '@rails/ujs';
import { isVisible, toggleElement } from '../lib/utils';

export default class extends Controller {
  static targets = ['value', 'form', 'input'];

  static classes = ['editing'];

  get changed() {
    return this.inputTarget.value !== this.initialValue;
  }

  get inputElement() {
    return Array.from(
      this.formTarget.querySelectorAll('input, select')
    ).find((e) => isVisible(e));
  }

  initialize() {
    this.initialValue = this.inputTarget.value;
    this.onBlur = this.hideForm.bind(this);
  }

  showForm() {
    if (isVisible(this.valueTarget)) {
      this.toggleForm();
      this.inputElement.focus();
      if (this.inputElement.select) {
        this.inputElement.select();
      }
      this.inputElement.addEventListener('blur', this.onBlur);
    }
  }

  hideForm() {
    if (isVisible(this.formTarget)) {
      this.toggleForm();
      this.inputElement.removeEventListener('blur', this.onBlur);

      if (!this.inputTarget.dataset.disableBlurEvent) { this.save(); }
    }
  }

  toggleForm() {
    this.element.classList.toggle(this.editingClass);
    toggleElement(this.valueTarget);
    toggleElement(this.formTarget);
  }

  save() {
    if (this.changed) {
      fire(this.formTarget, 'submit');
    }
  }
}
