import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [ 'field', 'counter' ]

  static values = {
    limit: Number
  }

  initialize() {
    this.limitCharacters()
  }

  limitCharacters() {
    this.counterTarget.innerHTML = this.fieldTarget.value.length;

    if(this.fieldTarget.value.length >= this.limitValue) {
      this.fieldTarget.classList.add('character-limit__error')
    } else {
      this.fieldTarget.classList.remove('character-limit__error')
    }
  }
}
