import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['menu'];

  handleDocumentClick = (e) => {
    if (!this.isVisible) { return; }
    if (this.element.contains(e.target)) { return; }
    this.hide();
  };

  connect() {
    document.addEventListener('click', this.handleDocumentClick);
  }

  disconnect() {
    this.hide();
    document.removeEventListener('click', this.handleDocumentClick);
  }

  get isVisible() {
    return this.menuTarget.classList.contains('dropdown__menu--visible');
  }

  toggle(e) {
    if (e) { e.preventDefault(); }
    if (this.isVisible) { this.reset(); }
    this.menuTarget.classList.toggle('dropdown__menu--visible');
  }

  show(e) {
    if (e) { e.preventDefault(); }
    this.menuTarget.classList.add('dropdown__menu--visible');
  }

  select(e) {
    if (e) { e.preventDefault(); }
    this.element.querySelectorAll('.fa-check').forEach((i) => { i.style.display = 'none'; });
    e.currentTarget.querySelector('.fa-check').style.display = 'inline';
    this.hide();
  }

  hide() {
    if (this.isVisible) { this.toggle(); }
  }

  keepSubmenuOpen(e) {
    const submenu = e.target;
    const menuItem = submenu.parentElement.querySelector('.dropdown__item');
    menuItem.classList.add('dropdown__item--hover');
  }

  keepNestedMenu(e) {
    e.relatedTarget.classList.add('dropdown__item--hover');
  }

  resetNestedMenu(e) {
    const element = e.target.parentElement.querySelector('.dropdown__item--hover');
    if (element) {
      element.classList.remove('dropdown__item--hover');
    }
  }

  reset() {
    this.element.querySelectorAll('.dropdown__item--hover').forEach((e) => {
      e.classList.remove('dropdown__item--hover');
    });
  }
}
