import { Controller } from 'stimulus';
import { ajax } from '@rails/ujs';

export default class extends Controller {
  static targets = ['modal', 'title', 'cancel', 'confirm'];

  show() {
    if(this.hasModalTarget) {
      this.updateContent();
      this.modalTarget.modal.show();
    }
  }

  updateContent() {
    this.titleTarget.innerHTML = 'BEFORE YOU PROCEED...';
    this.confirmTarget.innerHTML = 'PROCEED';
    this.cancelTarget.innerHTML = 'GO BACK';
    this.confirmTarget.classList.add('button--danger');
  }

  onConfirm(event) {
    const { url } = event.currentTarget.dataset
    if(!url) { return; }

    ajax({
      type: 'PATCH',
      url,
      dataType: 'html'
    });
  }
}
