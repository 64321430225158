import { Controller } from 'stimulus';
import { Notyf } from 'notyf';

export const config = {
  ripple: false,
  position: {
    x: 'center',
    y: 'top'
  },
  types: [
    {
      type: 'success',
      background: '#35A456' // $green
    },
    {
      type: 'error',
      background: '#A72118', // $red
      duration: 0,
      dismissible: true
    }
  ]
};

export default class extends Controller {
  static values = {
    type: String,
    message: String
  };

  initialize() {
    const type = this.typeValue;
    const message = this.messageValue;
    if (type && message) {
      new Notyf(config)[type](message);
    }
  }
}
