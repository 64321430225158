import { Controller } from 'stimulus'

// Import TinyMCE
import tinymce from 'tinymce/tinymce'

import 'tinymce/icons/default/icons';
import 'tinymce/themes/silver/theme';
import 'tinymce/skins/ui/oxide/skin.min.css';
import 'tinymce/models/dom/model';

// Import plugins
import 'tinymce/plugins/autoresize';
import 'tinymce/plugins/code';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/help';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/preview';
import 'tinymce/plugins/table';
import 'tinymce/plugins/wordcount';
import 'tinymce/plugins/image';

export const config = {
  content_css: false,
  skin: false,
  toolbar: [
    'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media | forecolor backcolor',
    'table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat'
  ],
  mobile: {
    toolbar: [
      'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media ',
      'table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | ',
      'checklist numlist bullist indent outdent | emoticons charmap | removeformat | forecolor backcolor'
    ]
  },
  plugins: 'link lists fullscreen help preview table code autoresize wordcount image textcolor',
  menubar: false,
  style_formats: [
    { title: 'Heading 1', format: 'h1' },
    { title: 'Heading 2', format: 'h2' },
    { title: 'Heading 3', format: 'h3' },
    { title: 'Heading 4', format: 'h4' },
    { title: 'Heading 5', format: 'h5' },
    { title: 'Heading 6', format: 'h6' },
    { title: 'Paragraph', format: 'p'}
  ],
  block_formats: 'Paragraph=p; Heading 1=h1; Heading 2=h2; Heading 3=h3; Heading 4=h4; Heading 5=h5; Heading 6=h6;',
  line_height_formats: '1 1.2 1.4 1.6 2',
  indent_use_margin: true,
  paste_merge_formats: true,
  max_height: 700,
  min_height: 400,
  default_link_target: '_blank',
  link_title: false,
  autoresize_bottom_margin: 10,
  link_context_toolbar: true,
  relative_urls: false,
  browser_spellcheck: true,
  element_format: 'html',
  invalid_elements: ['span'],
  content_style: 'html { font-family: Roboto, sans-serif; line-height: 1.5; }',
  resize: true,
  images_upload_url: '/attachments',
  automatic_uploads: true
};

export default class extends Controller {
  static targets = ['input']

  static values = {
    nestedFormId: String,
    withDirtyForm: Boolean
  };

  connect () {
    tinymce.init({
      selector: `#${this.inputTarget.id}`,
      ...config
    })

    if(this.withDirtyFormValue) {
      tinymce.activeEditor.on("input", (event) => {
        this.dirtyFormController.enableDirtyForm(event);
      });

      tinymce.activeEditor.on("submit", () => {
        this.dirtyFormController.handleSubmit();
      });
    }
  }

  get dirtyFormController() {
    const element = document.querySelector(this.nestedFormIdValue);
    return this.application.getControllerForElementAndIdentifier(element, 'dirty-form');
  }

  disconnect () {
    tinymce.remove()
  }
}
