import Trix from 'trix';

// disable drag/drop file attachments
document.addEventListener('trix-file-accept', (event) => {
  event.preventDefault();
});

// configure underline functionality
// see: https://github.com/basecamp/trix/pull/266
Trix.config.textAttributes.underline = {
  style: { textDecoration: 'underline' },
  inheritable: true,
  parser: (element) => element.style.textDecoration === 'underline'
};

// Add the new button to the toolbar
// see: https://stackoverflow.com/questions/58805984/add-buttons-to-rails-actiontext-toolbar
document.addEventListener('trix-initialize', (event) => {
  const buttonHTML = '<button type="button" class="trix-button trix-button--icon trix-button--icon-underline" data-trix-attribute="underline" title="Underline" tabindex="-1">Underline</button>';

  event.target.toolbarElement
    .querySelector('.trix-button--icon-italic')
    .insertAdjacentHTML('afterend', buttonHTML);
});
