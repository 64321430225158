import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'visibilitySection',
    'statusDropdown',
    'productInput',
    'productTitle'
  ];

  connect() {
    if(this.hasStatusDropdownTarget)
      this.toggle();
    this.liveEdit();
  }

  toggle() {
    if (this.statusDropdownTarget.value == 'publish') {
      this.visibilitySectionTarget.classList.remove('d-none');
    } else {
      this.visibilitySectionTarget.classList.add('d-none');
    }
  }

  liveEdit() {
    var placeholder = this.productInputTarget.placeholder;

    if (this.productInputTarget.value.length == '') {
      this.productTitleTarget.innerText = placeholder;
    } else {
      this.productTitleTarget.innerText = this.productInputTarget.value
    }
  }
}
