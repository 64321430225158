import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['inputField', 'changeField'];

  static values = {
    old: String
  }

  liveEdit() {
    if(this.inputFieldTarget.value) {
      this.changeFieldTarget.innerHTML = this.inputFieldTarget.value
    } else {
      this.changeFieldTarget.innerHTML = this.oldValue
    }
  }
}
