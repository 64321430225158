import { Controller } from 'stimulus';

import tinymce from 'tinymce/tinymce'

export default class extends Controller {
  static targets = [
    'questionType',
    'questionScore',
    'optionContainer',
    'options',
    'optionTemplate',
    'questionContainer',
    'questionToggleIcon',
    'headingDescription',
    'headingType',
    'tinymce',
    'questionType',
    'correctOptionField'
  ];

  static classes = [ "option", "activeOption" ]

  connect() {
    this.setPrevQuestionTypeValue();
    this.toggleOption();
  }

  get options() {
    return this.optionContainerTarget.querySelectorAll(`.${this.activeOptionClass}`)
  }

  toggleOption() {
    if(!this.hasQuestionTypeTarget) { return; }
    let defaultScore = this.questionScoreTarget.dataset[this.toCamelCase(this.questionTypeTarget.value)]

    this.questionScoreTarget.classList.remove(this.prevQuestionTypeValue);
    this.questionScoreTarget.classList.add(this.questionTypeTarget.value);
    this.questionScoreTarget.value = defaultScore;

    if(this.questionTypeTarget.value == 'short_answer' || this.questionTypeTarget.value == 'long_answer') {
      this.optionsTarget.innerHTML = '';
      this.optionContainerTarget.style.display = 'none';
    } else {
      this.optionContainerTarget.style.display = 'block';
    }

    this.setPrevQuestionTypeValue();
  }

  selectOption(event) {
    if(this.options.length == 1 && this.questionTypeTarget.value == 'multiple_choice') {
      this.unSelectAllOptions();

      event.currentTarget.classList.add(this.activeOptionClass);
      event.currentTarget.querySelector('input').value = true;

      return;
    }

    event.currentTarget.classList.toggle(this.activeOptionClass);
    event.currentTarget.querySelector('input').value = event.currentTarget.classList.contains(this.activeOptionClass);
  }

  selectCorrectOption(event) {
    const { questionType, optionId } = event.currentTarget.dataset;
    var that = this;
    if(questionType == 'multiple_choice') {
      for(let element of this.optionsTarget.children) {
        element.classList.remove(that.activeOptionClass);
      }

      for(let option of this.correctOptionFieldTarget.options) {
        option.selected = false;
      }
    }

    for(let option of this.correctOptionFieldTarget.options) {
      if(optionId && (option.value == optionId)) {
        option.selected = !option.selected;
      }
    }
    event.currentTarget.classList.toggle(this.activeOptionClass);
  }

  unSelectAllOptions() {
    this.options.forEach(element => {
      element.classList.remove(this.activeOptionClass);
      element.querySelector('input').value = false;
    });
  }

  toggleQuestion() {
    let editor = tinymce.get(`${this.tinymceTarget.id}`);
    let editor_text = editor.getBody().innerText
    this.questionToggleIconTargets.forEach(element => {
      element.classList.toggle('d-none')
    })

    this.addTypeDescription(this.headingTypeTarget, `(${this.humanize(this.questionTypeTarget.value)})`);
    this.addTypeDescription(this.headingDescriptionTarget, `${editor_text.substring(0,29)}...`);
    this.questionContainerTarget.classList.toggle('d-none');
  }

  addTypeDescription(target, value) {
    if(target.innerText == '') {
      target.innerText = value;
    } else {
      target.innerText = '';
    }
  }

  setPrevQuestionTypeValue() {
    this.prevQuestionTypeValue = this.questionTypeTarget.value;
  }

  humanize(str) {
    var i, frags = str.split('_');
    for (i=0; i<frags.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(' ');
  }

  toCamelCase(str) {
    return str.replace(/[-_](.)/g, function(_match, group1) {
      return group1.toUpperCase();
    });
  }
}
