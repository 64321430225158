import { Controller } from 'stimulus';
import Sortable from 'sortablejs';
import { random } from 'lib/utils';

export default class extends Controller {
  static values = {
    handle: String,
    destination: String,
    destinationHandle: String
  };

  connect() {
    this.groupId = `group-${random()}`;
    this.sortable = Sortable.create(this.element, {
      group: {
        name: this.groupId,
        pull: 'clone',
        put: false
      },
      handle: this.handleValue,
      sort: false
    });

    this.destinationElement = document.querySelector(this.destinationValue);
    if (!this.destinationElement) { return; }

    this.destinationSortable = Sortable.create(this.destinationElement, {
      group: this.groupId,
      handle: this.destinationHandleValue,
      onAdd: this.fireAddEvent.bind(this),
      onEnd: this.fireEndEvent.bind(this)
    });
  }

  fireAddEvent(event) {
    const e = new CustomEvent('copydrag.add', { detail: event });
    this.destinationElement.dispatchEvent(e);
  }

  fireEndEvent(event) {
    const e = new CustomEvent('copydrag.end', { detail: event });
    this.destinationElement.dispatchEvent(e);
  }
}
