import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['sectionToggleIcon', 'sectionToggleContainer'];

  toggleSection() {
    if (this.hasSectionToggleIconTarget) {
      this.sectionToggleIconTargets.forEach(element => {
        element.classList.toggle('d-none');
      })

      this.sectionToggleContainerTarget.classList.toggle('d-none');
    }
  }
}
