import { Controller } from 'stimulus';
import IMask from 'imask';

export default class extends Controller {
  static targets = ['phoneNumber'];

  connect() {
    this.applyPhoneNumberMask();
  }

  applyPhoneNumberMask() {
    if (this.hasPhoneNumberTarget) {
      this.mask = IMask(this.phoneNumberTarget, {
        mask: '+{1}(000) 000-0000'
      });
    }
  }
}
