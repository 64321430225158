import { Controller } from 'stimulus';

export default class extends Controller {
  initialize() {
    this.hiddenField = document.querySelector('#license_type');
    this.update();
  }

  update() {
    this.hiddenField.value = this.element.value;
  }
}
