import { Controller } from 'stimulus';

export default class extends Controller {
  onClick = (event) => {
    if (this.isDropdown(event.target)) { return; }
    if (this.isHeader(event.target)) { return; }
    if (this.isForm(event.target)) { return; }
    if (this.isLink(event.target)) { return; }
    if (!this.hasLink(event.target)) { return; }

    event.preventDefault();
    event.stopPropagation();
    this.findLink(event.target).click();
  };

  connect() {
    this.element.addEventListener('click', this.onClick);
    this.initializeRows();
  }

  disconnect() {
    this.element.removeEventListener('click', this.onClick);
  }

  initializeRows() {
    this.element.querySelectorAll('tbody > tr').forEach((e) => {
      e.setAttribute('data-datatable-linkable', this.hasLink(e));
    });
  }

  isDropdown(element) {
    if (element.closest('.dropdown')) { return true; }
    return false;
  }

  isHeader(element) {
    if (element.closest('th')) { return true; }
    return false;
  }

  isForm(element) {
    if (element.closest('form')) { return true; }
    return false;
  }

  isLink(element) {
    if (element.closest('a')) { return true; }
    return false;
  }

  findLink(element) {
    return element.closest('tr').querySelector('a');
  }

  hasLink(element) {
    if (element.hasAttribute('data-datatable-linkable')) {
      return element.getAttribute('data-datatable-linkable') === 'true';
    }
    const link = this.findLink(element);
    if (link) {
      return !this.isDropdown(link);
    }
    return false;
  }
}
