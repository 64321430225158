import { Controller } from 'stimulus';
import { ajax } from '@rails/ujs';

export default class extends Controller {

  static values = {
    url: String
  };

  connect() {
    if (this.urlValue == '') { return; }

    ajax({
      type: 'GET',
      dataType: 'script',
      url: this.urlValue
    });
  }
}
