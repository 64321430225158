import { Controller } from 'stimulus';
import { enableFields, disableFields } from '../lib/utils';

export default class extends Controller {
  static targets = [
    'productType',
    'standardField',
    'subscriptionField',
    'annualPrice',
    'annualResellerPrice',
    'annualIncludedSeats',
    'annualAdditionalSeatPrice',
    'annualDiscountPercentage',
    'monthlyPrice',
    'monthlyResellerPrice',
    'monthlyIncludedSeats',
    'monthlyAdditionalSeatPrice'
  ];

  get annualDiscountPercentage() {
    return parseFloat(this.annualDiscountPercentageTarget.value.replace('%', ''));
  }

  initialize() {
    this.update();
  }

  update() {
    if (this.productTypeTarget.value === 'standard') {
      enableFields(this.standardFieldTargets);
      disableFields(this.subscriptionFieldTargets);
    } else {
      enableFields(this.subscriptionFieldTargets);
      disableFields(this.standardFieldTargets);
    }
  }

  calculateRates(e) {
    e.preventDefault();
    this.updateValue(
      this.monthlyPriceTarget,
      this.monthlyRate(this.annualPriceTarget.value)
    );
    this.updateValue(
      this.monthlyResellerPriceTarget,
      this.monthlyRate(this.annualResellerPriceTarget.value)
    );
    this.updateValue(
      this.monthlyAdditionalSeatPriceTarget,
      this.monthlyRate(this.annualAdditionalSeatPriceTarget.value)
    );
    this.updateValue(
      this.monthlyIncludedSeatsTarget,
      this.annualIncludedSeatsTarget.value
    );
  }

  monthlyRate(annualRate) {
    const annualCost = annualRate / (1 - this.annualDiscountPercentage / 100);
    const monthlyCost = annualCost / 12;
    return Math.round(monthlyCost * 100) / 100;
  }

  updateValue(field, value) {
    field.value = value;
    const event = new Event('input');
    field.dispatchEvent(event);
  }
}
