import { Controller } from 'stimulus';
import { ajax } from '@rails/ujs';
import { Notyf } from 'notyf';
import { config } from './notice_controller';
import { prepareJSONRequest, refreshPage } from '../lib/utils';

export default class extends Controller {
  static values = {
    id: Number,
    url: String,
    currentStageId: Number,
    previousStageId: Number,
    currentStepId: Number,
    previousStepId: Number,
    archived: Boolean
  };

  get stageElement() {
    return this.element.closest('.sequence__stage');
  }

  get stepElement() {
    return this.element.closest('.sequence__step');
  }

  get currentStageOrder() {
    const currentStage = this.findStageElement(this.currentStageIdValue);
    return Number(currentStage.dataset.stageOrder);
  }

  get previousStageOrder() {
    const previousStage = this.findStageElement(this.previousStageIdValue);
    return previousStage ? Number(previousStage.dataset.stageOrder) : 0;
  }

  get currentStepOrder() {
    const currentStep = this.findStepElement(this.currentStepIdValue);
    return currentStep ? Number(currentStep.dataset.stepOrder) : 0;
  }

  get previousStepOrder() {
    const previousStep = this.findStepElement(this.previousStepIdValue);
    return previousStep ? Number(previousStep.dataset.stepOrder) : 0;
  }

  get movedBackwards() {
    if (this.currentStageOrder > this.previousStageOrder) { return false; }
    if (this.currentStageOrder < this.previousStageOrder) { return true; }
    if (this.currentStepOrder < this.previousStepOrder) { return true; }

    return false;
  }

  get promptController() {
    const element = document.querySelector('#sequence-prompt-modal');
    return this.application.getControllerForElementAndIdentifier(element, 'sequence-prompt');
  }

  get restartController() {
    const element = document.querySelector('#sequence-restart-modal');
    return this.application.getControllerForElementAndIdentifier(element, 'sequence-restart');
  }

  get isSequenceView() {
    return this.element.closest('.sequence') !== null;
  }

  // Updates the position values of the sequence assignment card after drag/drop
  //
  onDragDrop() {
    if (this.archivedValue) { return; }

    const stageId = this.stageElement.dataset.id;
    const stepId = this.stepElement ? this.stepElement.dataset.id : null;
    this.setPositionValues(stageId, stepId);
  }

  // Used to move a sequence assignment card without dragging (dropdown for example).
  //
  // The element firing the event is expected to have data-stage-id and optionally
  // data-step-id set to determine the target position.
  //
  moveTo(event) {
    event.preventDefault();
    this.element.blur();

    const { stageId, stepId } = event.currentTarget.dataset;
    this.updatePosition(stageId, stepId);
    this.setPositionValues(stageId, stepId);
  }

  // Moves the sequence assignment element to the stage and step passed
  //
  updatePosition(stageId, stepId) {
    if (!this.isSequenceView) { return; }

    const wrapperElement = this.findStepElement(stepId) || this.findStageElement(stageId);
    const column = wrapperElement.querySelector('[data-sequence-dragdrop-target="column"]');
    column.appendChild(this.element);
  }

  // Sets the current and previous position (Stage/Steps) values for the sequence assignment card.
  //
  setPositionValues(stageId, stepId) {
    this.previousStageIdValue = this.currentStageIdValue;
    this.currentStageIdValue = stageId;

    this.previousStepIdValue = this.currentStepIdValue;
    this.currentStepIdValue = stepId;
  }

  // Called when the sequence assignment card is dropped on a different stage.
  //
  currentStageIdValueChanged() {
    if (!this.previousStageIdValue) { return; }

    this.processUpdate();
  }

  // Called when the sequence assignment card is dropped on either a different stage or step.
  //
  currentStepIdValueChanged() {
    if (!this.previousStepIdValue) { return; }
    if (this.currentStageIdValue !== this.previousStageIdValue) { return; }

    this.processUpdate();
  }

  processUpdate() {
    if (this.movedBackwards) {
      this.confirmRestart();
    } else {
      this.save(false);
    }
  }

  // Saves the position of the sequence assignment card.
  // Also restarts it, if restartActionValue is true.
  //
  save(restart) {
    const data = {
      stage_id: this.currentStageIdValue,
      step_id: this.currentStepIdValue,
      restart
    };

    ajax({
      type: 'PATCH',
      dataType: 'json',
      url: this.urlValue,
      data,
      beforeSend: prepareJSONRequest,
      success: () => {
        this.renderPromptActions();
      },
      error: (response) => {
        this.displayFlashMessage('error', response.error);
      }
    });
  }

  // Confirms the restart of the sequence by displaying a modal dialog.
  //
  confirmRestart(event) {
    if (event) { event.preventDefault(); }
    const type = event ? 'confirm' : 'prompt';
    this.restartController.sequenceAssignmentIdValue = this.idValue;
    this.restartController.show(type);
  }

  onRestartConfirm() { // type is passed but currently not used
    this.save(true);
  }

  onRestartCancel(type) {
    if (type === 'prompt') {
      this.save(false);
    }
  }

  // Renders any pending prompt actions for the sequence assignment
  //
  renderPromptActions() {
    this.promptController.sequenceAssignmentIdValue = this.idValue;
    this.promptController.refresh();
  }

  onPromptShow() {
    this.promptDisplayed = true;
  }

  onPromptClose() {
    this.renderPromptActions();
  }

  onPromptNotRequired() {
    if (this.promptDisplayed || !this.isSequenceView) {
      this.promptDisplayed = false;
      refreshPage();
    }
  }

  findStageElement(id) {
    return document.querySelector(`.sequence__stage[data-id='${id}']`) || document.querySelector(`.dropdown__item[data-stage-id='${id}']`);
  }

  findStepElement(id) {
    return document.querySelector(`.sequence__step[data-id='${id}']`) || document.querySelector(`.dropdown__item[data-step-id='${id}']`);
  }

  displayFlashMessage(type, content) {
    if (type && content) {
      new Notyf(config)[type](content);
    }
  }
}
