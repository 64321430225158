import { Controller } from 'stimulus';

export default class extends Controller {
  onInput = (e) => this.updateHiddenInput(e.target.value);

  connect() {
    this.element.addEventListener('input', this.onInput);
    this.initializeHiddenInput();
    this.initializeInput();
  }

  disconnect() {
    this.element.removeEventListener('input', this.onInput);
  }

  get value() {
    return this.element.getAttribute('value'); // use getAttribute to bypass autocomplete issues
  }

  initializeInput() {
    this.element.removeAttribute('name');
    if (this.value) {
      this.element.value = ((parseFloat(this.value) || 0) / 100).toFixed(2);
    }
  }

  initializeHiddenInput() {
    this.hiddenInput = this.buildHiddenInput();
    this.element.insertAdjacentElement('afterend', this.hiddenInput);
  }

  buildHiddenInput() {
    const hiddenInput = document.createElement('input');
    hiddenInput.setAttribute('type', 'hidden');
    hiddenInput.setAttribute('name', this.element.getAttribute('name'));
    hiddenInput.value = this.value;
    return hiddenInput;
  }

  updateHiddenInput(value) {
    this.hiddenInput.value = this.parseInputAsCents(value);
  }

  parseInputAsCents(value) {
    const parsedValue = value.replace(/[^0-9.]/gi, '');
    if (parsedValue) {
      return (parseFloat(parsedValue) || 0) * 100;
    }
    return '';
  }
}
