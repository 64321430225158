// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import 'assets/stylesheets/application.scss';

import 'controllers';

require('@rails/ujs').start();
require('lib/rails-confirm');
require('turbolinks').start();
require('lib/turbolinks-scroll');
require('lib/turbolinks-before-cache');
require('lib/turbolinks-remote-get-forms');
require('lib/turbolinks-error-pages');
require('@rails/activestorage').start();
require('lib/activestorage-direct-uploads');
require('lib/font-awesome');
require('trix');
require('@rails/actiontext');
require('lib/trix-customization');
require('lib/gtm-helpers');

// Examples:
// import Rails from '@rails/ujs';
// import { fire } from '@rails/ujs';

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
require.context('assets/images', true);
// const imagePath = (name) => images(name, true)
