import { Controller } from 'stimulus';
import { ajax } from '@rails/ujs';
import { prepareJSONRequest } from '../lib/utils';

export default class extends Controller {
  static values = { teamId: Number };

  connect() {
    this.dragSectionCallback = this.updateSections.bind(this);
    this.sectionWrapper.addEventListener('dragdrop.change', this.dragSectionCallback);
    if (this.formFieldWrapper) {
      this.addFormFieldCallback = this.addFormField.bind(this);
      this.moveFormFieldCallback = this.moveFormField.bind(this);
      this.formFieldWrapper.addEventListener('copydrag.add', this.addFormFieldCallback);
      this.formFieldWrapper.addEventListener('copydrag.end', this.moveFormFieldCallback);
    }
  }

  disconnect() {
    this.sectionWrapper.removeEventListener('dragdrop.change', this.dragSectionCallback);
    if (this.formFieldWrapper) {
      this.formFieldWrapper.removeEventListener('copydrag.add', this.addFormFieldCallback);
      this.formFieldWrapper.removeEventListener('copydrag.end', this.moveFormFieldCallback);
    }
  }

  get sectionWrapper() {
    return this.element.querySelector('.form-builder__sections');
  }

  get sections() {
    return Array.from(this.element.querySelectorAll('.form-builder__section'));
  }

  get formFieldWrapper() {
    return this.element.querySelector('.form-builder__form-fields');
  }

  get formFieldCount() {
    return this.formFieldWrapper.querySelectorAll('.form-builder__form-field').length;
  }

  updateSections() { /* event param passed */
    this.sections.forEach((element, index) => {
      element.querySelector('.form-builder__section-number').innerText = index + 1;
    });
  }

  addFormField(event) {
    const url = this.formFieldWrapper.dataset.addFormFieldUrl;
    if (!url) { return; }

    const { item } = event.detail;
    const data = JSON.parse(item.dataset.fieldParams);
    data.position = this.formFieldSortPosition(event);

    ajax({
      type: 'POST',
      url,
      dataType: 'json',
      data: {
        team_id: this.teamIdValue,
        template_field: data
      },
      beforeSend: prepareJSONRequest
    });
  }

  moveFormField(event) {
    let url = this.formFieldWrapper.dataset.moveFormFieldUrl;
    if (!url) { return; }

    url = url.replace(':id', event.detail.item.dataset.id);
    const data = new FormData();
    data.append('position', this.formFieldSortPosition(event));

    ajax({
      type: 'POST',
      url,
      dataType: 'json',
      data
    });
  }

  formFieldSortPosition(event) {
    return Math.min(this.formFieldCount, event.detail.newIndex + 1);
  }
}
