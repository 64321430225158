import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['category'];

  toggleDropdown(e) {
    const { sequenceCategories } = e.currentTarget.dataset;
    const categoriesMap = JSON.parse(sequenceCategories);
    const currentSequenceId = e.currentTarget.value;

    if (currentSequenceId) {
      this.categoryTarget.value = categoriesMap[currentSequenceId];
      this.categoryTarget.disabled = true;
    } else {
      this.categoryTarget.disabled = false;
    }
  }
}
