import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['plan', 'interval', 'planInfo'];

  initialize() {
    this.update();
  }

  update() {
    let activeIndex = this.planTarget.selectedIndex;
    if (this.intervalTarget.value === 'month') {
      activeIndex += this.planTarget.options.length;
    }
    for (let i = 0; i < this.planInfoTargets.length; i++) {
      if (i === activeIndex) {
        this.planInfoTargets[i].style.display = 'block';
      } else {
        this.planInfoTargets[i].style.display = 'none';
      }
    }
  }
}
