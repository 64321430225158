// Based off of: https://github.com/rails/rails/pull/32404
import Rails from '@rails/ujs';
import MicroModal from 'micromodal';

class ConfirmDialog {
  constructor(message, onConfirm) {
    this.message = message;
    this.modal = this.modal || document.querySelector('#confirm-modal');
    this.content = this.modal.querySelector('#confirm-content');
    this.confirmButton = this.modal.querySelector('#confirm-button');
    this.onConfirm = onConfirm;
  }

  show() {
    this.content.innerText = this.message;
    MicroModal.show(this.modal.id, {
      disableFocus: true,
      onClose: () => this.confirmButton.removeEventListener('click', this.onConfirm)
    });
    this.confirmButton.addEventListener('click', this.onConfirm);
  }
}

Rails.confirm = (message, element) => {
  const dialog = new ConfirmDialog(message, () => {
    const old = Rails.confirm;
    Rails.confirm = () => true;
    element.click();
    Rails.confirm = old;
  });
  dialog.show();
  return false;
};
