import { Controller } from 'stimulus';
import { ajax } from '@rails/ujs';

export default class extends Controller {
  static values = {
    url: String
  };

  updateList(event) {
    let link = new URL(this.urlValue)
    let searchParams = link.searchParams;
    searchParams.append('message_template[category]', event.currentTarget.value);
    link.search = searchParams.toString();
    let url = link.toString();

    if(!url) { return;}

    ajax({
      type: 'GET',
      url: url,
      dataType: 'script'
    });
  }
}
