import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['sideNav', 'slideIcon'];

  toggleNav() {
    this.slideIconTargets.forEach(element => {
      element.classList.toggle('d-none');
    });

    this.sideNavTarget.classList.toggle('nav__transition-0');
  }
}
