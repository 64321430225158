import { Controller } from 'stimulus';
import { ajax } from '@rails/ujs';
import { Notyf } from 'notyf';
import { config } from './notice_controller';

export default class extends Controller {
  static targets = ['toggleContainer'];

  static values = {
    id: Number,
    settingsUrl: String,
    toggleContainerClass: String
  };

  saveState(event) {
    const { settingsKey } = event.currentTarget.dataset;
    let keys = JSON.parse(settingsKey)

    if(keys.length == 0) { return; }
    const data = new FormData();

    let that = this;
    this.toggleContainerTargets.forEach((element, index) => {
      data.append(keys[index], element.classList.contains(that.toggleContainerClassValue));
    });

    ajax({
      type: 'PATCH',
      dataType: 'json',
      url: this.settingsUrlValue,
      data,
      error: (response) => {
        this.displayFlashMessage('error', response.error);
      }
    });
  }

  displayFlashMessage(type, content) {
    if (type && content) {
      new Notyf(config)[type](content);
    }
  }
}
