import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['sidenavContainer', 'collapseIcon', 'courseContainer', 'footerContainer'];
  static values = {
    toggleClass: String,
    sectionDetailsClass: String,
    toggleIconClass: String,
    sectionDetailsId: String,
    isToggleIcon: Boolean,
    footerClass: String
  };

  get toggleSectionDetailsClass() {
    return document.querySelector(this.sectionDetailsIdValue)
  }

  connect () {
    this.toggleKlass();
  }

  toggleSideNav(e) {
    let that = this;
    if (e) { e.preventDefault(); }

    this.sidenavContainerTarget.classList.toggle(this.toggleClassValue);
    if(this.hasFooterContainerTarget) { this.footerContainerTarget.classList.toggle(this.footerClassValue); }
    if(this.isToggleIconValue) { this.toggleIcons(); }
    this.toggleKlass();
  }

  toggleIcons() {
    this.collapseIconTargets.forEach(element => {
      if(this.hasToggleIconClassValue) {
        element.classList.toggle(this.toggleIconClassValue);
      }
    });
  }

  toggleKlass() {
    if (this.sidenavContainerTarget.classList.contains(this.toggleClassValue)) {
      this.toggleSectionDetailsClass.classList.remove(this.sectionDetailsClassValue);
    } else {
      this.toggleSectionDetailsClass.classList.add(this.sectionDetailsClassValue);
    }
  }
}
