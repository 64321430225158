import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'visibilitySection',
    'statusDropdown',
  ];

  connect() {
    if(this.hasStatusDropdownTarget) {
      this.toggle();
    }
  }

  toggle() {
    if (this.statusDropdownTarget.value == 'team_form') {
      this.visibilitySectionTarget.classList.remove('d-none');
    } else {
      this.visibilitySectionTarget.classList.add('d-none');
    }
  }
}
