import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['attachmentContainer', 'uploadButton'];

  static values = {
    disableUpload: Boolean
  };

  connect() {
    this.disableUploadButton();
  }

  updateAttachments(event) {
    const { files, multiple } = event.target;

    for (const file of files) {
      if (multiple) {
        this.attachmentContainerTarget.innerHTML += `<div class="mt-2">${file.name}</div>`;
      } else {
        this.attachmentContainerTarget.innerHTML = `<div class="mt-2">${file.name}</div>`;
      }
    }
    this.disableUploadButton();
  }

  disableUploadButton() {
    if (!this.disableUploadValue) { return; }

    const uploadButton = this.uploadButtonTarget;

    if (this.attachmentContainerTarget.children.length === 0) {
      uploadButton.disabled = true;
    } else {
      uploadButton.disabled = false;
    }
  }

  validateFile(event) {
    const allowedFileExtensionRegex = /^audio\/.*/;
    const file = event.target.files[0];
    const isValidFile = file && allowedFileExtensionRegex.test(file.type);

    if (!isValidFile) {
      event.preventDefault();
      this.showError(event);
    } else {
      this.clearError(event);
    }

    this.toggleSubmitButtons(event, !isValidFile);
  }

  showError(event) {
    const field = this.getClosestFormField(event.target);
    field.classList.add('form__field--invalid')
    field.querySelector('.form__error').textContent = 'File has an invalid content type'
  }

  clearError(event) {
    const field = this.getClosestFormField(event.target);
    field.classList.remove('form__field--invalid')
    field.querySelector('.form__error').textContent = ''
  }

  toggleSubmitButtons(event, disable) {
    const submitButtons = this.getSubmitButtons(event);
    submitButtons.forEach(button => {
      button.disabled = disable;
    });
  }

  getClosestFormField(element) {
    return element.closest('.form__field');
  }

  getSubmitButtons(event) {
    return event.target.closest('#section-form').querySelectorAll("[type='submit']");
  }
}
