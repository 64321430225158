import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["searchInput", "accountItem"];

  search() {
    const searchTerm = this.searchInputTarget.value.toLowerCase();
    const accountItems = this.accountItemTargets;

    accountItems.forEach((item) => {
      const accountName = item.textContent.toLowerCase();

      if (accountName.includes(searchTerm)) {
        item.classList.remove('d-none');
      } else {
        item.classList.add('d-none');
      }
    });
  }

  onInput() {
    this.search();
  }

  onBackspace(event) {
    if (event.key === "Backspace") {
      this.search();
    }
  }
}
