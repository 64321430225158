import { Controller } from 'stimulus';

export default class extends Controller {
  static values = {
    activeContainerClass: String
  }

  get activeContainer() {
    return this.element.querySelector(this.activeContainerClassValue)
  }

  connect() {
    setTimeout(()=>{
      this.scrollIntoView();
    }, 300)
  }

  scrollIntoView() {
    this.activeContainer.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center'
    });
  }
}
