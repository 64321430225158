import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['checkbox', 'selectedValue', 'coursesSelector'];

  toggleCourseSelection(e) {
    if (e.target.value === 'selected') {
      this.coursesSelectorTarget.classList.remove('form__field--hidden');
    } else {
      this.coursesSelectorTarget.classList.add('form__field--hidden');
    }
  }

  selectPrivacySetting(e) {
    const isPublic = e.currentTarget.dataset.value === '1';

    this.checkboxTarget.checked = isPublic;
    this.selectedValueTarget.innerHTML = isPublic ? 'Public' : 'Private';
  }
}