import { Controller } from 'stimulus';
import { Notyf } from 'notyf';
import tippy from 'tippy.js';
import { config } from './notice_controller';

export default class extends Controller {
  static values = {
    copied: String
  }
  static targets = ['source'];

  initialize() {
    this.copiedValue ||= 'Copied!'
  }

  copy(e) {
    e.preventDefault();
    if (this.sourceTarget.value) {
      this.copyValue(this.sourceTarget);
    } else {
      const input = document.createElement('textarea');
      input.value = this.sourceTarget.textContent;
      document.body.appendChild(input);
      this.copyValue(input);
      input.remove();
    }
    this.showConfirmation(e.currentTarget);
    this.displayToaster('success', this.copiedValue);
  }

  copyValue(input) {
    input.select();
    document.execCommand('copy');
  }

  showConfirmation(element) {
    if (element._tippy) { return; } // eslint-disable-line no-underscore-dangle

    tippy(element, {
      content: 'Copied!',
      boundary: 'viewport',
      trigger: 'manual',
      onShow(instance) {
        setTimeout(() => {
          if (instance.state.isVisible) {
            instance.hide();
          }
        }, 1500);
      },
      onHidden(instance) {
        instance.destroy();
      }
    }).show();
  }

  displayToaster(type, content) {
    if (type && content) {
      new Notyf(config)[type](content);
    }
  }
}
