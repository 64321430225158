import { Controller } from 'stimulus';
import Sortable from 'sortablejs';
import { ajax } from '@rails/ujs';

export default class extends Controller {
  static values = {
    handle: String,
    draggable: String,
    url: String
  };

  connect() {
    this.sortable = Sortable.create(this.element, {
      handle: this.handleValue,
      draggable: this.draggableValue,
      onChange: this.fireChangeEvent.bind(this),
      onEnd: this.save.bind(this)
    });
  }

  fireChangeEvent(event) {
    const e = new CustomEvent('dragdrop.change', { detail: event });
    this.element.dispatchEvent(e);
  }

  save(event) {
    if (!this.hasUrlValue) { return; }

    const { id } = event.item.dataset;
    const data = new FormData();
    data.append('position', event.newIndex + 1);

    ajax({
      type: 'POST',
      url: this.url(id),
      dataType: 'json',
      data
    });
  }

  url(id) {
    return this.urlValue.replace(':id', id);
  }
}
