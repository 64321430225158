/* globals Turbolinks */

export function prepareJSONRequest(xhr, options) {
  xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
  options.data = JSON.stringify(options.data);
  return true;
}

export function addHiddenInput(name, value, element, position = 'afterbegin') {
  const hiddenInput = document.createElement('input');
  hiddenInput.setAttribute('type', 'hidden');
  hiddenInput.setAttribute('name', name);
  hiddenInput.value = value;
  element.insertAdjacentElement(position, hiddenInput);
}

export function enableFields(fields) {
  fields.forEach((e) => {
    e.style.display = 'block';
    e.querySelectorAll('input, select').forEach((input) => {
      input.disabled = false;
    });
  });
}

export function disableFields(fields) {
  fields.forEach((e) => {
    e.style.display = 'none';
    e.querySelectorAll('input, select').forEach((input) => {
      input.disabled = true;
    });
  });
}

export function random(max = 1000000) {
  return Math.floor(Math.random() * max);
}

export function cancelEvent(event) {
  event.preventDefault();
  event.stopPropagation();
}

export function isDateSelector(element) {
  if (element.classList.contains('flatpickr-calendar')) { return true; }
  if (element.closest('.flatpickr-calendar')) { return true; }

  return false;
}

export function isVisible(element) {
  const { display } = window.getComputedStyle(element);
  return display !== 'none';
}

export function toggleElement(element) {
  element.style.display = isVisible(element) ? 'none' : 'block';
}

export function isRichTextField(element) {
  return !!element.tagName.match(/trix-editor/i);
}

export function isRequiredField(element) {
  return element.hasAttribute('required');
}

export function isMobile() {
  return window.innerWidth <= 800;
}

export function refreshPage() {
  const url = new URL(window.location.toString());
  url.searchParams.set('t', random());
  Turbolinks.visit(url.toString(), { action: 'replace' });
}
