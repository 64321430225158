import { Controller } from 'stimulus';
import { config } from "./tinymce_controller";

import tinymce from 'tinymce/tinymce'

export default class extends Controller {
  static targets = [
    'question',
    'questionToggleIcon',
    'tinymce',
    'headingIndex',
    'questionType',
    'headingDescription',
    'headingType',
    'quizQuestion',
    'dirtyField',
    'formField'
  ]

  static values = {
    nestedFormId: String,
    withDirtyForm: Boolean
  };

  connect() {
    this.questionConfigration();

    if(this.questionWrapper) {
      this.questionConfigrationCallback = this.questionConfigration.bind(this);
      this.questionWrapper.addEventListener('dragdrop.change', this.questionConfigrationCallback);
    }
  }

  get questionWrapper() {
    return this.element.querySelector('.question-wrapper-element');
  }

  get dirtyFormController() {
    const element = document.querySelector(this.nestedFormIdValue);
    return this.application.getControllerForElementAndIdentifier(element, 'dirty-form');
  }

  collapseAllQuestions() {
    this.questionTargets.forEach(element => {
      element.classList.add('d-none');
    });

    this.toggleQuestionIcons('fa-caret-right');
    this.addTypeDescription(this.headingTypeTargets, this.questionTypeTargets);
    this.addTypeDescription(this.headingDescriptionTargets, this.tinymceTargets);
  }

  expandAllQuestions() {
    this.questionTargets.forEach(element => {
      element.classList.remove('d-none');
    });

    this.toggleQuestionIcons('fa-caret-down');
    this.removeTypeDescription(this.headingTypeTargets)
    this.removeTypeDescription(this.headingDescriptionTargets)
  }

  toggleQuestionIcons(klass) {
    this.questionToggleIconTargets.forEach(element => {
      if(element.classList.contains(klass)) {
        element.classList.remove('d-none')
      } else {
        element.classList.add('d-none')
      }
    });
  }

  addTypeDescription(targets, elements) {
    targets.forEach((element, index)=> {
      if(elements[0].nodeName == "TEXTAREA") {
        let editor = tinymce.get(`${elements[index].id}`);
        element.innerText = `${editor.getBody().innerText.substring(0,29)}...`
      } else {
        element.innerText = `(${this.humanize(elements[index].value)})`
      }
    })
  }

  removeTypeDescription(targets) {
    targets.forEach(element => {
      element.innerText = '';
    })
  }

  questionConfigration(event) {
    this.setTinymce(event);
    this.updateQuestionHeading();
    this.applyDirtyFormOnDirtyFields();
  }

  updateQuestionHeading() {
    let index = 1;
    if(!this.hasHeadingIndexTarget) { return; }

    this.headingIndexTargets.forEach((element, i) => {
      if(!(this.quizQuestionTargets[i].style.display == 'none')) {
        element.innerText = index;
        index++;
      }
    });
  }

  setTinymce(event) {
    var that = this;
    if(!this.hasTinymceTarget) { return; }
    if(event?.type == 'dragdrop.change') { tinymce.remove(); }

    tinymce.map(this.tinymceTargets, function(element) {
      tinymce.init({
        selector: `#${element.id}`,
        ...config
      })

      if(that.withDirtyFormValue) {
        tinymce.activeEditor.on("input", () => {
          that.dirtyFormController.enableDirtyForm();
        });

        tinymce.activeEditor.on("submit", () => {
          that.dirtyFormController.handleSubmit();
        });
      }
    })
  }

  applyDirtyFormOnDirtyFields() {
    if(!this.withDirtyFormValue) { return; }

    this.dirtyFieldTargets.forEach(element => {
      element.addEventListener("click", () => {
        this.dirtyFormController.enableDirtyForm();
      });
    });
  }

  preventFormSubmission(event) {
    let anyNillValue = false;
    let message = 'Are you sure you want to submit without any response?';

    this.formFieldTargets.forEach(field => {
      if((field.nodeName == 'SELECT' || field.nodeName == 'TEXTAREA' || field.nodeName == 'INPUT') && field?.value == '') {
        anyNillValue = true;
      } else {
        if(field?.innerHTML != '' || field?.value != '') {
          anyNillValue = false;
        } else {
          if(field?.innerHTML == '') { anyNillValue = true; }
        }
      }
    })

    if(anyNillValue && !confirm(message)) { event.preventDefault(); }
  }

  humanize(str) {
    var i, frags = str.split('_');
    for (i=0; i<frags.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(' ');
  }

  updateValue(event) {
    const { questionType } = event.currentTarget.dataset;

    for(let element of this.element.querySelectorAll(`.${questionType}`)) {
      element.value = event.currentTarget.value
    }
  }

  disconnect() {
    tinymce.remove();
  }
}
