import { Controller } from "stimulus"
import Tribute from "tributejs"
import Trix from "trix"

export default class extends Controller {
  static targets = [ "field" ]
  static classes = [ "menuContainer" ]

  static values = {
    url: String
  };

  connect() {
    this.editor = this.fieldTarget.editor
    this.initializeTribute()
  }

  disconnect() {
    this.tribute.detach(this.fieldTarget)
  }

  async initializeTribute() {
    let sliderMenu = document.getElementsByClassName(this.menuContainerClass)[0]

    const response = await fetch(this.urlValue)
    const tributeUsers = await response.json();

    this.tribute = new Tribute({
      allowSpaces: true,
      lookup: 'full_name',
      values: tributeUsers,
      menuContainer: sliderMenu
    })

    this.tribute.attach(this.fieldTarget)
    this.tribute.range.pasteHtml =  this._pasteHtml.bind(this)
    this.fieldTarget.addEventListener("tribute-replaced", this.replaced)
    this.fieldTarget.addEventListener("tribute-no-match", this.noMatchFound)
  }

  replaced(e) {
    let mention = e.detail.item.original
    let attachment = new Trix.Attachment({
      content: mention.content,
      sgid: mention.sgid
      })
    this.editor.insertAttachment(attachment)
  }

  _pasteHtml(html, startPos, endPos) {
    let position = this.editor.getPosition()
    let det = this.editor.setSelectedRange([startPos+position-endPos,position])
    this.editor.deleteInDirection("backward")
  }

  noMatchFound(e) {
    e.detail.style.display = 'none'
  }
}
