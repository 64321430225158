// Monkey patch Turbolinks to render 403, 404, 422 & 500 normally
// See https://github.com/turbolinks/turbolinks/issues/179

const requestLoadedOrig = window.Turbolinks.HttpRequest.prototype.requestLoaded;

window.Turbolinks.HttpRequest.prototype.requestLoaded = function requestLoaded() {
  const { status } = this.xhr;
  if (status === 404 || status === 422 || status === 500) {
    return this.endRequest(() => {
      this.delegate.requestCompletedWithResponse(
        this.xhr.responseText,
        this.xhr.getResponseHeader('Turbolinks-Location')
      );
    });
  }
  return requestLoadedOrig.bind(this)();
};
