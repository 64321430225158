import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['selectedIds', 'modalButton', 'saveButton'];
  static values = { persistedIds: Array, currentAccountId: String }

  toggleSaveButtons() {
    if (!this.removedIds().length == 0) {
      this.modalButtonTarget.classList.remove('d-none')
      this.saveButtonTarget.classList.add('d-none')
    } else {
      this.modalButtonTarget.classList.add('d-none')
      this.saveButtonTarget.classList.remove('d-none')
    }
  }

  removedIds() {
    const persistedValues = this.persistedIdsValue.map(String).filter(item => item !== this.currentAccountIdValue);
    const selectedValues = Array.from(this.selectedIdsTarget.selectedOptions).map(option => option.value);

    const result = persistedValues.filter(value => !selectedValues.includes(value));
    return result
  }
}
