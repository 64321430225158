import { Controller } from 'stimulus';
import { ajax } from '@rails/ujs';

export default class extends Controller {
  static targets = [
    'subjectInput',
    'bodyInput',
    'subjectPlaceholder',
    'bodyPlaceholder',
    'attachmentsInput',
    'attachmentsPlaceholder'
  ];

  static values = {
    fullName: String,
    firstName: String,
    lastName: String,
    jobTitle: String,
    accountName: String,
    jobRef: String,
    sequenceAssignee: String,
    sequenceAssigner: String,
    sequenceId: String,
    sequenceName: String,
    teamId: String,
    teamName: String
  };

  get savedAttachments() {
    const elements = this.element.querySelectorAll('.form__attachment .attachment__link');
    return Array.from(elements).map((e) => e.innerText);
  }

  show() {
    this.subjectPlaceholderTarget.innerHTML = this.subject();
    this.bodyPlaceholderTarget.innerHTML = this.body();
    this.attachmentsPlaceholderTarget.innerHTML = this.attachments();

    const element = document.getElementById('preview-message-template-modal');
    element.modal.show();
  }

  subject() {
    return this.applyTemplating(this.subjectInputTarget.value) || 'N/A';
  }

  body() {
    return this.applyTemplating(this.bodyInputTarget.value) || 'N/A';
  }

  attachments() {
    const attachmentsList = this.savedAttachments;

    const attachedFiles = this.attachmentsInputTarget.files;
    for (let index = 0; index < attachedFiles.length; index++) {
      attachmentsList.push(attachedFiles[index].name);
    }

    return this.attachmentsListHTML(attachmentsList);
  }

  applyTemplating(string) {
    const mapping = this.mapping();
    let output = string;

    Object.keys(mapping).forEach((key) => {
      output = output.replaceAll(key, mapping[key]);
    });

    return output;
  }

  mapping() {
    const templateVariableMapping = {
      '{{full_name}}': this.fullNameValue,
      '{{first_name}}': this.firstNameValue,
      '{{last_name}}': this.lastNameValue,
      '{{job_title}}': this.jobTitleValue,
      '{{job_ref}}': this.jobRefValue,
      '{{team_id}}': this.teamIdValue,
      '{{team_name}}': this.teamNameValue,
      '{{sequence_id}}': this.sequenceIdValue,
      '{{sequence_name}}': this.sequenceNameValue,
      '{{sequence_assigner}}': this.sequenceAssignerValue,
      '{{sequence_assignee}}': this.sequenceAssigneeValue,
      '{{account_name}}': this.accountNameValue
    };

    return templateVariableMapping;
  }

  attachmentsListHTML(attachmentsList) {
    const ul = document.createElement('ul');
    ul.setAttribute(
      'style',
      'list-style: none; padding: 0px; text-decoration: underline;'
    );

    attachmentsList.forEach((listItem) => {
      const li = document.createElement('li');

      li.appendChild(document.createTextNode(listItem));
      ul.appendChild(li);
    });

    return ul.outerHTML;
  }
}
