import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['radioInput', 'timeDelaySection', 'dateTimeSection'];

  get waitType() {
    let value = 'none';
    this.radioInputTargets.forEach((element) => {
      if (element.checked) { value = element.value; }
    });
    return value;
  }

  connect() {
    this.updateForm();
  }

  updateForm() {
    this.timeDelaySectionTarget.hidden = true;
    this.dateTimeSectionTarget.hidden = true;

    if (this.waitType === 'time_delay') {
      this.timeDelaySectionTarget.hidden = false;
    } else if (this.waitType === 'datetime') {
      this.dateTimeSectionTarget.hidden = false;
    }
  }
}
