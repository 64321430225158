import { Controller } from 'stimulus';
import { ajax } from '@rails/ujs';

export default class extends Controller {
  static targets = ['repeatInput', 'disconnectHostLink', 'hostDropdown', 'occurrencesSection'];

  connect() {
    this.updateDisconnectLinkedAccountURL();
    this.updateForm();
  }

  updateRepeatDropdown(e) {
    const [yyyy, mm, dd] = e.currentTarget.value.split('-');
    const date = new Date(yyyy, mm - 1, dd); // splitting avoids timezone issues

    const dayName = date.toLocaleString('en-us', { weekday: 'long' });
    const weekNumber = Math.ceil(date.getDate() / 7);

    this.repeatOptions[2].innerHTML = `Weekly on ${dayName}`;
    this.repeatOptions[3].innerHTML = `Monthly on the ${this.weekOrdinal(weekNumber)} ${dayName}`;
  }

  weekOrdinal(weekNumber) {
    const monthlyWeeks = ['first', 'second', 'third', 'fourth', 'last'];

    return monthlyWeeks[weekNumber - 1];
  }

  get repeatOptions() {
    return this.repeatInputTarget.options;
  }

  updateDisconnectLinkedAccountURL() {
    const hostId = this.hostDropdownTarget.value;

    if (this.disconnectHostLinkTarget.href.includes(':id?')) {
      this.disconnectHostLinkTarget.href = this.disconnectHostLinkTarget.href.replace(':id', hostId);
    } else {
      const url = this.disconnectHostLinkTarget.href.split('?');

      url[0] = url[0].replace(/.$/, `${hostId}?`);
      this.disconnectHostLinkTarget.href = url[0].concat(url[1]);
    }
  }

  updateForm() {
    if (this.repeatInputTarget.value == 'none') {
      this.occurrencesSectionTarget.classList.add('d-none');
    } else {
      this.occurrencesSectionTarget.classList.remove('d-none');
    }
  }

  connectDifferentZoomAccount(event) {
    const { url } = event.currentTarget.dataset;

    if(!url) { return; }

    ajax({
      type: 'GET',
      url: url,
      dataType: 'script'
    });
  }
}
