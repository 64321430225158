import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['sectionStart', 'sectionInProgress'];

  static values = {
    status: String
  };

  get submitButton() {
    return this.element.closest('form').querySelector('.form__submit input');
  }

  initialize() {
    this.updateForm();
  }

  updateForm() {
    if (!this.submitButton) { return; }

    if (this.statusValue !== 'complete') {
      this.submitButton.disabled = true;
    } else {
      this.submitButton.disabled = false;
    }
  }

  start() {
    this.sectionStartTarget.style.display = 'none';
    this.sectionInProgressTarget.style.display = 'block';
  }
}
