function trackEvent(name, data = {}) {
  window.dataLayer = window.dataLayer || [];
  if ('ecommerce' in data) {
    window.dataLayer.push({ ecommerce: null });
  }
  window.dataLayer.push({ event: name, ...data });
}

window.scTrackEvent = trackEvent;
export default trackEvent;
