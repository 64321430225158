import '@simonwep/pickr/dist/themes/nano.min.css';

import { Controller } from 'stimulus';
import Pickr from '@simonwep/pickr';

const settings = {
  useAsButton: true,
  theme: 'nano',
  lockOpacity: true,
  components: {
    preview: true,
    hue: true,
    interaction: {
      input: true,
      cancel: true,
      save: true
    }
  },
  i18n: {
    'btn:save': 'OK'
  }
};

export default class extends Controller {
  static values = {
    cssVariable: String
  };

  initialize() {
    this.pickr = new Pickr({
      el: this.element,
      default: this.element.value,
      ...settings
    });
    this.initEvents();
    this.initStyleElement();
  }

  get currentColor() {
    return this.pickr.getColor().toHEXA().toString(0);
  }

  get selectedColor() {
    return this.pickr.getSelectedColor().toHEXA().toString(0);
  }

  initEvents() {
    this.pickr.on('change', () => {
      this.updateStyle(this.currentColor);
    });
    this.pickr.on('save', () => {
      this.element.value = this.selectedColor;
      this.pickr.hide();
    });
    this.pickr.on('cancel', () => {
      this.pickr.hide();
    });
    this.pickr.on('hide', () => {
      this.updateStyle(this.selectedColor);
    });
  }

  initStyleElement() {
    if (!this.cssVariableValue) { return; }

    this.styleElement = document.createElement('style');
    this.styleElement.type = 'text/css';
    document.querySelector('body').appendChild(this.styleElement);
  }

  updateStyle(color) {
    if (!this.cssVariableValue) { return; }

    this.styleElement.innerHTML = `:root { --${this.cssVariableValue}: ${color}; }`;
  }
}
