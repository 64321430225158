import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['input', 'show', 'hide'];

  static values = {
    visible: Boolean,
    autocomplete: String
  };

  initialize() {
    this.updateIcons();
  }

  updateIcons() {
    if (this.visibleValue) {
      this.showTarget.style.display = 'none';
      this.hideTarget.style.display = 'block';
    } else {
      this.hideTarget.style.display = 'none';
      this.showTarget.style.display = 'block';
    }
  }

  toggle() {
    if (this.visibleValue) {
      this.inputTarget.setAttribute('type', 'password');
      this.inputTarget.setAttribute('autocomplete', this.autocompleteValue);
      this.visibleValue = false;
    } else {
      this.inputTarget.setAttribute('type', 'text');
      this.inputTarget.setAttribute('autocomplete', 'off');
      this.visibleValue = true;
    }
    this.inputTarget.focus();
    this.updateIcons();
  }
}
