// https://thelucid.com/2019/02/20/handling-get-remote-forms-with-turbolinks-in-rails-6
import Turbolinks from 'turbolinks';

document.addEventListener('turbolinks:load', () => {
  document.querySelectorAll('form[method=get][data-remote=true]').forEach((form) => {
    form.addEventListener('ajax:beforeSend', (event) => {
      const { detail } = event;
      const options = detail[1];

      Turbolinks.visit(options.url);
      event.preventDefault();
    });
  });
});
