import { Controller } from 'stimulus'

export default class extends Controller {
  connect(options={}) {
    this.form = this.element;
    this.changeDirectUpload = false;
    this.isDirty = false;
    this.initialValues = {};
    this.fields = [
      ...this.form.elements,
      ...this.form.querySelectorAll('trix-editor')
    ]
    this.message = options['message'] || 'You have unsaved changes!\nAre you sure, you want to continue?';

    delete window.allowRedirect;
    this.setupFields();
    this.setFormHandlers();
    this.fireChangeEvent.bind(this)
  }

  get dirtyLinks() {
    let links = [];
    let documentLinks = document.querySelectorAll('a:not([target="_blank"])');
    let formLinksSet = new Set(this.form.querySelectorAll('a'));

    for(let link of documentLinks) {
      if(!formLinksSet.has(link)) { links.push(link) };
    }

    return links
  }

  fireChangeEvent(event) {
    const e = new CustomEvent('dirty-form.change', { detail: event });
    this.element.dispatchEvent(e);
  }

  setupFields() {
    this.fields.forEach(field => {
      if (!field.name || field.type == 'submit' || field.type == 'button' || field.type == 'hidden') {
        return;
      }

      // Save initial values
      this.initialValues[field.name] = field.value;

      // Set handlers
      if (field.nodeName == 'TRIX-EDITOR') {
        field.addEventListener('trix-change', this.checkValue.bind(this));
      } else {
        field.addEventListener('change', this.checkValue.bind(this));
        field.addEventListener('input', this.checkValue.bind(this));
      }
    })
  }

  setFormHandlers() {
    // Handle leaving page
    window.onbeforeunload = () => {
      if (this.isDirty) {
        return this.message;
      }
    };
    if (typeof Turbolinks !== 'undefined') {
      this.dirtyLinks.forEach(link => {
        link.addEventListener('click', (event) => {
          if(!this.isDirty || (this.changeDirectUpload && window.allowRedirect)) { return; }

          if (this.isDirty && !confirm(this.message)) {
            event.preventDefault()
          } else {
            this.isDirty = false;
          }
        });
      })
    }
  }

  checkValue(event) {
    let field = event.target;
    const {directUploadUrl} = field.dataset;

    if(directUploadUrl) { this.changeDirectUpload = true }

    if(this.changeDirectUpload) {
      window.addEventListener('submit', this.enableDirtyForm.bind(this));
      this.form.addEventListener('submit', this.enableDirtyForm.bind(this));
    } else {
      window.addEventListener('submit', this.handleSubmit.bind(this));
      this.form.addEventListener('submit', this.handleSubmit.bind(this));
    }

    if (this.initialValues[field.name] != field.value) {
      this.enableDirtyForm();
    }
  }

  enableDirtyForm() {
    this.isDirty = true;
  }

  handleSubmit() {
    this.isDirty = false;
  }

  disconnect() {
    this.isDirty = false;
  }
}
