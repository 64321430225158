import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['productDetails', 'selfEnrollment', 'field'];

  initialize() {
    this.updateForm();
  }

  updateForm() {
    let displayProperty = this.selfEnrollmentTarget.checked ? 'block' : 'none';
    this.productDetailsTarget.style.display = displayProperty;

    this.toggleFieldsRequired();
  }

  toggleFieldsRequired() {
    this.fieldTargets.forEach(element => {
      if (this.selfEnrollmentTarget.checked) {
        element.setAttribute('required', true);
      } else {
        element.removeAttribute('required');
      }
    })
  }
}
