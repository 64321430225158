import { Controller } from 'stimulus';
import flatpickr from 'flatpickr';

export default class extends Controller {
  static values = {
    minDate: String
  };

  connect() {
    this.datePicker = flatpickr(this.element, {
      monthSelectorType: 'dropdown',
      minDate: this.minDateValue,
      altInput: true,
      altFormat: 'M j, Y'
    });
  }

  disconnect() {
    this.datePicker.destroy();
  }
}
