import { Controller } from 'stimulus';

export default class extends Controller {
  show(e) {
    e.preventDefault();
    const { target, targetUrl } = e.currentTarget.dataset;
    const element = document.getElementById(target);
    element.modal.show({ url: targetUrl });
  }
}
