import { Controller } from 'stimulus';
import { ajax } from '@rails/ujs';

export default class extends Controller {
  static values = {
    sequenceAssignmentId: Number,
    url: String
  };

  get contentElement() {
    return this.element.querySelector('.modal__container');
  }

  get sequenceAssignmentController() {
    const element = document.querySelector(`[data-sequence-assignment-id-value="${this.sequenceAssignmentIdValue}"]`);
    return this.application.getControllerForElementAndIdentifier(element, 'sequence-assignment');
  }

  get url() {
    return this.urlValue.replace(':id', this.sequenceAssignmentIdValue);
  }

  refresh() {
    ajax({
      type: 'PATCH',
      dataType: 'json',
      url: this.url,
      success: (response) => {
        if (response) {
          this.contentElement.innerHTML = response.prompt;
          this.element.modal.show({
            onShow: this.onShow.bind(this),
            onClose: this.onClose.bind(this)
          });
        } else {
          this.onNotRequired();
        }
      }
    });
  }

  onShow() {
    this.sequenceAssignmentController.onPromptShow();
  }

  onClose() {
    this.sequenceAssignmentController.onPromptClose();
  }

  onNotRequired() {
    this.sequenceAssignmentController.onPromptNotRequired();
  }
}
