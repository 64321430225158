import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['value'];

  static values = { key: String };

  initialize() {
    this.formAction = this.element.action;
    this.updateForm();
  }

  updateForm() {
    const { value } = this.valueTarget;
    this.element.action = this.formAction.replace(this.keyValue, value);
  }
}
