import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['tab', 'tabData'];

  static values = {
    activeTabClass: String
  }

  handleTab(event) {
    if(!event.currentTarget.classList.contains(this.activeTabClassValue)) {
      this.toggleClass(this.tabTargets, this.activeTabClassValue);
      this.toggleClass(this.tabDataTargets, 'd-none');
      event.currentTarget.classList.add(this.activeTabClassValue);
    }
  }

  toggleClass(targets, className) {
    targets.forEach(element => {
      element.classList.toggle(className);
    });
  }
}
