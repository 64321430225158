import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['dropdownOptions'];

  static values = {
    margin: Number
  }

  setSubMenuContainer(event) {
    var count = 0;
    var visibleDropdown = document.querySelector('.dropdown__menu--visible');
    var dropdownButtons = visibleDropdown.querySelectorAll('.dropdown__parent--item');
    for (const element of dropdownButtons) {
      if(event.currentTarget.dataset.optionName == element.dataset.optionName) { break; }

      count++
    }

    this.dropdownOptionsTarget.style.marginTop = `${this.marginValue * count}px`
  }
}
