import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['checkbox'];

  static values = {
    state: String
  };

  update() {
    if (this.stateValue === 'unknown') {
      this.updateState('yes');
    } else if (this.stateValue === 'yes') {
      this.updateState('no');
    } else {
      this.updateState('unknown');
    }
    return true;
  }

  setIndeterminateState(e) {
    e.preventDefault();

    this.updateState('no');
  }

  updateState(value) {
    this.stateValue = value;

    if (value === 'yes') {
      this.checkboxTarget.checked = true;
      this.checkboxTarget.indeterminate = false;
    } else if (value === 'no') {
      this.checkboxTarget.checked = false;
      this.checkboxTarget.indeterminate = true;
    } else {
      this.checkboxTarget.checked = false;
      this.checkboxTarget.indeterminate = false;
    }
  }
}
