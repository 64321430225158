import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['richTextField', 'forumButton'];

  static values = {
    publicComment: String,
    privateComment: String
  }

  changeButtonText() {
    setTimeout(()=> {
      if(this.richTextFieldTarget.querySelector(".mention")) {
        this.forumButtonTarget.value = this.privateCommentValue;
      } else {
        this.forumButtonTarget.value = this.publicCommentValue;
      }
    }, 200)
  }
}
