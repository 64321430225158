import { Controller } from 'stimulus';

export default class extends Controller {
  get checkboxes() {
    return this.element.closest('.form__field').querySelectorAll('input[type="checkbox"]');
  }

  initialize() {
    this.checked = false;
  }

  toggle(event) {
    event.preventDefault();
    this.checked = !this.checked;
    this.checkboxes.forEach((e) => { e.checked = this.checked; });
  }
}
